.skeleton {
    width: 100%;
    height: 100%;

    .ske_card {
        width: 100%;
        height: 100%;
        background-color: white;

        .search-result-container {
            display: block !important;
        }
    }

    // .ske_card_50{

    // }
}

.dash_skeleton {
    .dash_flex_responsive {
        display: flex;
        justify-content: space-around;

        div {
            width: 30%;
            margin: 10px
        }
    }

    .dash_flex_responsive2 {
        display: flex;
        justify-content: space-around;

        div {
            width: 45%;
        }
    }

}

@media only screen and (max-width: 991px) and (min-width:600px) {
    .dash_flex_responsive {
        div {
            width: 47%;
        }
    }
}

@media only screen and (max-width: 767px) and (min-width:600px) {
    .dash_flex_responsive {
        div {
            width: 47%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .dash_flex_responsive {
        // flex-direction: column;
        flex-wrap: wrap;

        div {
            width: 45% !important;
            margin: 6px;
        }
    }

    .dash_flex_responsive2 {
        flex-direction: column;

        div {
            width: 100% !important;
        }
    }
}