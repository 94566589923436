.bid-summary-table {
    position: relative;
    overflow: auto;
    width: 300px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #013a6d;
        //background: #d8dbe0;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #d8dbe0;
        //background: #013a6d;
    }

    .table {
        width: 100%;
        margin-bottom: 0;

        thead {
            th {
                position: sticky;
                top: 0;
                z-index: 1;
                background: #d8dbe0;
                //padding: 14px 20px;
                //color: #223354;
                //text-transform: uppercase;
                //font-size: 13px;
                //font-weight: 700;
                //line-height: 15px;
                text-align: center;
            }
        }

        tbody {
            tr {
                td {
                    padding: 2px;
                    text-align: center;
                }
            }

            //
            //
            //    td {
            //        .content {
            //            h5 {
            //                color: #223354;
            //                font-size: 15px;
            //                font-weight: 700;
            //                padding: 14px 20px 14px 10px;
            //            }
            //        }
            //    }
        }
    }

}

mark,
.mark {
    //padding: 0.2em;
    //padding: 0;
    background-color: #e4c522 !important;
}

.back-button {
    border: none;
    background: transparent;
    padding: 0;
}

.in_active_link {
    pointer-events: none;
    background-color: #013b6d77 !important;
}

.in_active_link:hover {
    cursor: no-drop;
}
.bid-details-top .profile-info-title {
    font-size: 17px;
}

.bid-details-page .bid-details-top {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}

.bid-details-page .bid-details-bottom {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}
.bid-details-page .bid-details-bottom h5.pb-3 {
    font-size: 16px;
    margin-bottom: 2px;
    font-size: 18px;
    width: 100%;
    line-height: 26px;
    color: #636b93;
    font-weight: 700;
}

.bid-details-page .bid-details-bottom .table {
    text-align: left;
}
.bid-details-page .bid-details-bottom .table {
    text-align: left;
    border: 1px solid #fff !important;
}

.bid-details-page .bid-details-bottom .action-block .button-1 {
	padding: 0px 20px;
	height: 40px;
	border: none;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	border-radius: 4px;
	background: #4b81ff;
	color: #fff;
}
.bid-details-page {
    .biddetails-content {
        background: #fff;
        padding: 10px 20px;
        .single {
            h3 {
                font-size: 18px;
                width: 100%;
                line-height: 26px;
                color: #636b93;
                font-weight: 700;
            }

            span {
                line-height: 26px;
                color: #636b93;
                font-weight: 400 !important;
                font-size: 15px;
            }
        }
    }
    
    .bid-details-open-doc {
        background: #fff;
        text-align: right !important;
        padding-top: 30px;
        .in_active_link.button-1 {
            padding: 8px 16px;
            border-radius: 2px;
            font-size: 12px;
        }
    }

    .biddetails-content.full-content {
        padding-top: 20px;
        position: relative;

        .bid-details-add-fav-btn {
            position: absolute;
            top: 13px;
            right: 12px;

            button {
                border: none;
                padding: 6px 20px;
                font-size: 11px;
                border-radius: 2px;
                transition: all 0.3s ease;
                text-transform: uppercase;
                font-weight: 700;
                background: rgba(93, 120, 255, 0.18);
                color: rgb(93 120 255);
                transition:all .3s ase;
                line-height: 18px;
                &.fav_active{
                    color: #fff;
                    background:  rgb(242 134 133);
                    &:hover{
                        background:  rgb(242 134 133);
                    }
                }
                &:hover{
                    color: #fff;
                    background:  rgb(93 120 255);
                }
                i.fa.fa-circle-o-notch.fa-spin {
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }

        .single {
            h3 {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 12px;
            }

            p {
                color: #4a4086;
                line-height: 30px;
                font-size: 16px;
                font-weight: 600;
                border: 1px solid #fff;
                padding: 0px;
            }

            p.description {
                font-weight: 400 !important;
                font-size: 14px !important;
                color: #7b7b7b !important;
            }
        }

        .single-itm {
            display: flex;
            margin-bottom: 10px;
            padding-bottom: 15px;
            border-bottom: 1px dashed #c2ddb9;

            &.mb-none {
                margin-bottom: 0px;
                border: none;
            }
            strong {
                width: 150px !important;
                font-size: 14px !important;
                color: #636b93;
                font-weight: 500;
                line-height: 26px;

                i {
                    color: #4b81ff;
                    font-size: 14px;
                    margin-right: 3px;
                }
            }

            p.title {
                width: calc(100% - 150px);
                font-size: 14px !important;
                line-height: 26px;
                color: #636b93;
                font-weight: 500;
                &.title.main-title {
                    padding-right: 158px;
                }
                span {
                    margin-right: 6px;
                }

                .button-1.src-btn {
                    padding: 5px 20px;
                    border-radius: 0px;
                    background-color: rgba(10, 187, 135, 0.1) !important;
                    color: #0abb87;
                    font-size: 12px;
                    font-weight: 600;
                    padding-bottom: 7px;

                    i {
                        margin-right: 5px;
                    }
                    
                    &:hover {
                        background-color: #0abb87 !important;
                        color: #fff;
                    }
                    

                    
                }
            }
            
            &.status {
                p {
                    text-transform: capitalize;
                    color: #636b93;
                    font-weight: 600;
                    &.applied {
                        color: green;
                    }
                    &.rejected {
                        color: rgb(255, 81, 81);
                    }
                    &.rewarded{
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}

.similler-bid-and-awards {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  .similler-bid-and-awards-single {
      background: #fff;
      max-width: 49% !important;
      padding: 0;
      h3 {
          padding: 18px 22px;
          font-size: 16px;
          font-weight: 600;
          color: #636b93;
          border-bottom: 1px solid #636b9333;
       }
    }
  .similler-bid-and-awards-single-full {
      padding: 18px 22px;
      .item {
          margin-bottom: 15px;
          background: rgba(10, 187, 135, 0.07);
          border-radius: 5px;
          padding: 12px 14px;
          h4 {
              font-size: 14px;
              margin-bottom: 10px;
              font-weight: 600;
              color: #636b93;
              a {
                  color: #2e334a;
                  &:hover{
                    color: #4b81ff;
                  }
                }
            }
          ul {
              display: flex;
              li {
                  margin-right: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #636b93;
                  i {
                  color: #4b81ff;
                  margin-right: 3px;
                  }
                }
               li:last-child {
                  margin-right: 0px;
                }
            }
        }
        .item:last-child {
          margin-bottom: 0px;
        }

        .item:nth-child(2) {
          background: rgba(255, 184, 34, 0.1);
        }
        .item:nth-child(3) {
            background: rgba(253, 57, 122, 0.1);
        }
       .item:nth-child(4) {
            background: rgba(85, 120, 235, 0.1);
        }
   }

}
.bid-summary-table-data {
    ul {
      display: block;
      width: 100%;
      border-bottom: 1px dashed #c1c1c1;
      text-align: center;
      display: flex;
      li {
      padding: 10px 0px;
    }
    li {
      text-align: center;
      width: 30%;
    }
      li:nth-child(1) {
          width: 70%;
        }
    }
    ul.t-head {
      background: #7f90ea3d;
      border: none;
      li {
          font-weight: 700;
          font-size: 15px;
          color: #181164;
        }
    }
}
.bid-details-page .bid-details-bottom .action-block .form-submit.save-button {
	border-radius: 0px;
	padding: 14px 0px;
	margin-top: 10px;
	background-color: var(--primary-color) !important;
}
.status_label_full {
	margin-top: 9px;
}
.bid-summary-table-no-data {
  text-align: center;
  padding: 15px 3px;
  font-size: 14px;
}
@media (max-width:991px){
    .bid-summary-table {
        position: relative;
        overflow: auto;
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width:576px){
    .bid-details-page .biddetails-content.full-content .single-itm {
        strong {
            width: 112px !important;
            font-size: 12px !important;
            font-weight: 500;
        }

        p.title {
            width: calc(100% - 112px);
            font-size: 12px !important;
        }
        .title.main-title {
            padding-right: 0px !important;
        }
        
    }
    .bid-details-page .biddetails-content.full-content .bid-details-add-fav-btn {
        position: relative;
        margin-bottom: 15px;
        top: 0;
        left: 0;
    }
    .similler-bid-and-awards .similler-bid-and-awards-single {
        max-width: 100% !important;
        margin-bottom: 20px;
    }
}