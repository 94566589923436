@import '~react-accessible-accordion/dist/fancy-example.css';

/**
* Template Name: Mentor - v4.3.0
* Template URL: https://bootstrapmade.com/mentor-free-education-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    color: #444444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F2F3F8;
}

:root {
    --primary-color: #5d78ff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    color: #013A6D;
    text-decoration: none;
}

a:hover {
    color: #4F4F4F;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #013A6D;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
    text-align: center;
    padding-top: 0px;
}

.back-to-top i {
    font-size: 20px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #4F4F4F;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #5fcf80;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 2s !important;
    }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    box-shadow: 0px 0 18px rgba(55, 66, 59, 0.08);
}

#header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

#header .logo a {
    color: #5fcf80;
}

#header .logo img {
    max-height: 40px;
}

/**
* Get Startet Button
*/
.get-started-btn {
    margin-left: 22px;
    background: #5fcf80;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
}

.get-started-btn:hover {
    background: #3ac162;
    color: #fff;
}

@media (max-width: 768px) {
    .get-started-btn {
        margin: 0 15px 0 0;
        padding: 6px 18px;
    }
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 80vh;
    background: url("../src/assets/img/hero-bg.jpg") top center;
    background-size: cover;
    position: relative;
}

#hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    padding-top: 72px;
}

@media (max-width: 992px) {
    #hero .container {
        padding-top: 62px;
    }
}

#hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    font-family: "Poppins", sans-serif;
}

#hero h2 {
    color: #eee;
    margin: 10px 0 0 0;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    border: 2px solid #fff;
    color: #fff;
}

#hero .btn-get-started:hover {
    background: #5fcf80;
    border: 2px solid #5fcf80;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero {
        height: 100vh;
    }

    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/


.section-bg {
    background-color: #f6f7f6;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #9ae1af;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #37423b;
}

.breadcrumbs {
    margin-top: 73px;
    text-align: center;
    background: #5fcf80;
    padding: 30px 0;
    color: #fff;
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 63px;
    }
}

.breadcrumbs h2 {
    font-size: 32px;
    font-weight: 500;
}

.breadcrumbs p {
    font-size: 14px;
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-bottom: 10px;
}

.about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #5fcf80;
}

.about .content .learn-more-btn {
    background: #5fcf80;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px 9px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
}

.about .content .learn-more-btn:hover {
    background: #3ac162;
    color: #fff;
}

@media (max-width: 768px) {
    .about .content .learn-more-btn {
        margin: 0 48px 0 0;
        padding: 6px 18px;
    }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    padding: 30px 0;
}

.counts .counters span {
    font-size: 48px;
    display: block;
    color: #5fcf80;
    font-weight: 700;
}

.counts .counters p {
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #37423b;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
    padding: 30px;
    background: #5fcf80;
    border-radius: 4px;
    color: #fff;
}

.why-us .content h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
}

.why-us .content p {
    margin-bottom: 30px;
}

.why-us .content .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
}

.why-us .content .more-btn i {
    font-size: 14px;
}

.why-us .content .more-btn:hover {
    color: #5fcf80;
    background: #fff;
}

.why-us .icon-boxes .icon-box {
    text-align: center;
    background: #fff;
    padding: 40px 30px;
    width: 100%;
    border: 1px solid #eef0ef;
}

.why-us .icon-boxes .icon-box i {
    font-size: 32px;
    padding: 18px;
    color: #5fcf80;
    margin-bottom: 30px;
    background: #ecf9f0;
    border-radius: 50px;
}

.why-us .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 30px 0;
}

.why-us .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
    padding-top: 0px;
}

.features .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    transition: 0.3s;
    border: 1px solid #eef0ef;
}

.features .icon-box i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 1;
}

.features .icon-box h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
}

.features .icon-box h3 a {
    color: #37423b;
    transition: 0.3s;
}

.features .icon-box:hover {
    border-color: #5fcf80;
}

.features .icon-box:hover h3 a {
    color: #5fcf80;
}

/*--------------------------------------------------------------
# Courses
--------------------------------------------------------------*/
.courses .course-item {
    border-radius: 5px;
    border: 1px solid #eef0ef;
}

.courses .course-content {
    padding: 15px;
}

.courses .course-content h3 {
    font-weight: 700;
    font-size: 20px;
}

.courses .course-content h3 a {
    color: #37423b;
    transition: 0.3s;
}

.courses .course-content h3 a:hover {
    color: #5fcf80;
}

.courses .course-content p {
    font-size: 14px;
    color: #777777;
}

.courses .course-content h4 {
    font-size: 14px;
    background: #5fcf80;
    padding: 7px 14px;
    color: #fff;
    margin: 0;
}

.courses .course-content .price {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    color: #37423b;
}

.courses .trainer {
    padding-top: 15px;
    border-top: 1px solid #eef0ef;
}

.courses .trainer .trainer-profile img {
    max-width: 50px;
    border-radius: 50px;
}

.courses .trainer .trainer-profile span {
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #5a6c60;
}

.courses .trainer .trainer-rank {
    font-size: 18px;
    color: #657a6d;
}

/*--------------------------------------------------------------
# Trainers
--------------------------------------------------------------*/
.trainers .member {
    text-align: center;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #eef0ef;
}

.trainers .member img {
    margin: -1px -1px 30px -1px;
}

.trainers .member .member-content {
    padding: 0 20px 30px 20px;
}

.trainers .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
}

.trainers .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
}

.trainers .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: #aaaaaa;
}

.trainers .member .social {
    margin-top: 15px;
}

.trainers .member .social a {
    color: #7e9486;
    transition: 0.3s;
}

.trainers .member .social a:hover {
    color: #5fcf80;
}

.trainers .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
    padding-left: 50px;
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    border: 1px solid #eef0ef;
    position: relative;
    background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    position: absolute;
    left: -45px;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #c1ecce;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #5fcf80;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #5fcf80;
}

@media (max-width: 767px) {
    .testimonials .testimonial-wrap {
        padding-left: 0;
    }

    .testimonials .testimonial-item {
        padding: 30px;
        margin: 15px;
    }

    .testimonials .testimonial-item .testimonial-img {
        position: static;
        left: auto;
    }
}

/*--------------------------------------------------------------
# Cource Details
--------------------------------------------------------------*/
.course-details h3 {
    font-size: 24px;
    margin: 30px 0 15px 0;
    font-weight: 700;
    position: relative;
    padding-bottom: 10px;
}

.course-details h3:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: #eef0ef;
    bottom: 0;
    left: 0;
}

.course-details h3:after {
    content: '';
    position: absolute;
    display: block;
    width: 60px;
    height: 1px;
    background: #5fcf80;
    bottom: 0;
    left: 0;
}

.course-details .course-info {
    background: #f6f7f6;
    padding: 10px 15px;
    margin-bottom: 15px;
}

.course-details .course-info h5 {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.course-details .course-info p {
    margin: 0;
    font-weight: 600;
}

.course-details .course-info a {
    color: #657a6d;
}

/*--------------------------------------------------------------
# Cource Details Tabs
--------------------------------------------------------------*/
.cource-details-tabs {
    overflow: hidden;
    padding-top: 0;
}

.cource-details-tabs .nav-tabs {
    border: 0;
}

.cource-details-tabs .nav-link {
    border: 0;
    padding: 12px 15px 12px 0;
    transition: 0.3s;
    color: #37423b;
    border-radius: 0;
    border-right: 2px solid #e2e7e4;
    font-weight: 600;
    font-size: 15px;
}

.cource-details-tabs .nav-link:hover {
    color: #5fcf80;
}

.cource-details-tabs .nav-link.active {
    color: #5fcf80;
    border-color: #5fcf80;
}

.cource-details-tabs .tab-pane.active {
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
}

.cource-details-tabs .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #37423b;
}

.cource-details-tabs .details p {
    color: #777777;
}

.cource-details-tabs .details p:last-child {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .cource-details-tabs .nav-link {
        border: 0;
        padding: 15px;
    }

    .cource-details-tabs .nav-link.active {
        color: #fff;
        background: #5fcf80;
    }
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/
.events .card {
    border: 0;
    padding: 0 30px;
    margin-bottom: 60px;
    position: relative;
}

.events .card-img {
    width: calc(100% + 60px);
    margin-left: -30px;
    overflow: hidden;
    z-index: 9;
    border-radius: 0;
}

.events .card-img img {
    max-width: 100%;
    transition: all 0.3s ease-in-out;
}

.events .card-body {
    z-index: 10;
    background: #fff;
    border-top: 4px solid #fff;
    padding: 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-top: -60px;
    transition: 0.3s;
}

.events .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.events .card-title a {
    color: #37423b;
    transition: 0.3s;
}

.events .card-text {
    color: #5e5e5e;
}

.events .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.3s;
}

.events .read-more a:hover {
    color: #5fcf80;
}

.events .card:hover img {
    transform: scale(1.1);
}

.events .card:hover .card-body {
    border-color: #5fcf80;
}

.events .card:hover .card-body .card-title a {
    color: #5fcf80;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    border: 1px solid #eef0ef;
    border-radius: 0;
    position: relative;
    overflow: hidden;
}

.pricing h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
}

.pricing h4 {
    font-size: 36px;
    color: #5fcf80;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}

.pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}

.pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.pricing ul li {
    padding-bottom: 16px;
}

.pricing ul i {
    color: #5fcf80;
    font-size: 18px;
    padding-right: 4px;
}

.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}

.pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
}

.pricing .btn-buy {
    background: #5fcf80;
    display: inline-block;
    padding: 8px 35px;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
}

.pricing .btn-buy:hover {
    background: #3ac162;
}

.pricing .featured h3 {
    color: #fff;
    background: #5fcf80;
}

.pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #5fcf80;
    color: #fff;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
    padding-top: 5px;
}

.contact .info {
    width: 100%;
    background: #fff;
}

.contact .info i {
    font-size: 20px;
    color: #5fcf80;
    float: left;
    width: 44px;
    height: 44px;
    background: #ecf9f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #37423b;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #657a6d;
}

.contact .info .email,
.contact .info .phone {
    margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
    background: #5fcf80;
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #5fcf80;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
    background: #5fcf80;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #3ac162;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    color: #37423b;
    font-size: 14px;
    background: #eef0ef;
}

#footer .footer-top {
    padding: 60px 0 30px 0;
    background: #f9faf9;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #777777;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #5fcf80;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #4F4F4F;
}

#footer .footer-newsletter {
    font-size: 15px;
}

#footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    text-align: left;
    border: 1px solid #e0e5e2;
}

#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #5fcf80;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: #3ac162;
}

#footer .credits {
    padding-top: 5px;
    font-size: 13px;
}

#footer .credits a {
    color: #3ac162;
    transition: 0.3s;
}

#footer .credits a:hover {
    color: #5fcf80;
}

.social-links a {
    font-size: 18px;
    display: inline-block;
    background: #013A6D;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.social-links a:hover {
    background: #4F4F4F;
    color: #fff;
    text-decoration: none;
}

.pagination-component .pagination-container li.pagination-item {
    border-radius: 3px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #d8d9f0c2;
    font-weight: 600;
    padding: 0 2px !important;
}

.pagination-component .pagination-container li.pagination-item.selected {
    background: #5d85ff;
    color: #fff;
    border-color: #5d85ff;
    border-radius: 2px;
}

.bid-card-item-single .bid-footer ul li .content-f span.status-open {
    color: green !important;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
}

.bid-card-item-single .bid-footer ul li .content-f span.status-other {
    color: rgb(255, 81, 81) !important;;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
}
.bid-card-item-single .bid-footer ul li .content-f span.status-awarded{
    color: var(--primary-color) !important;;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
}
.profile-page-my-bid-page .profile-header {
    background: #fff;
    border: none !important;
    padding: 10px 20px !important;
}

/*
 * Single Bid
*/
.bid-card-item-single {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    transition: all .3s ease;
    box-shadow: 0px 2px 10px #dddddd57;
    border-color: #fff;
    background: #fff;
}

.bid-card-item-single:hover {
    box-shadow: 0px 4px 12px #ddd;
    border-color: #fff;
}

.bid-card-item-single .c-header {
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    padding-bottom: 4px;
    position: relative;
}

.bid-card-item-single .c-header h4 {
    font-size: 16px;
    font-weight: 400;
    padding-right: 146px;
    padding-bottom: 10px;
}

.bid-card-item-single .c-header h4 a {
    color: #56546d;
    font-weight: 600;
}

.bid-card-item-single .c-header h4 a:hover {
    color: #5578eb;
}

.bid-card-item-single .c-header button.bookmark-btn {
    width: 120px;
    border: none;
    padding: 8px 0px;
    font-size: 11px;
    border-radius: 2px;
    background: rgb(93 120 255 / 18%);
    color: rgb(93 120 255);
    transition: all .3s ease;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    top: 13px;
    right: 20px;
}

.bid-card-item-single .c-header button.bookmark-btn i {
    margin-right: 3px;
}

.bid-card-item-single .c-header button.bookmark-btn:hover {
    color: #fff;
    background: rgb(93 120 255);
}

.bid-card-item-single .content-a {
    padding: 0px 20px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.bid-card-item-single .content-a p {
    font-size: 14px;
    line-height: 26px;
    color: #3d3d63;
    font-weight: 400;
}

.bid-card-item-single .bid-footer {
    padding: 15px 20px;
    border-top: 1px solid #ebedf2;
    margin-top: 20px;
    display: inline-block;
    width: 100%;
}

.bid-card-item-single .bid-footer ul {
    display: flex;
    justify-content: space-between;
}

.bid-card-item-single .bid-footer ul li {
    display: flex;
    margin-right: 30px;
    width: 25%;
}

.bid-card-item-single .bid-footer ul li:last-child {
    margin-right: 0px;
}

.bid-card-item-single .bid-footer ul li .icon {
    font-size: 25px !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffb822;
    color: transparent;
    margin-right: 10px;
}

.bid-card-item-single .bid-footer ul li .content-f strong {
    display: block;
    line-height: 1;
    font-size: 14px;
    color: #595d6e;
    font-weight: 400;
}

.bid-card-item-single .bid-footer ul li .content-f span {
    font-size: 13px;
    color: #48465b;
    font-weight: 400;
    text-transform: capitalize;
    display: inline-block;
    margin-top: 2px;
}

.no-bid-found-text {
    width: 100%;
    text-align: center;
    display: block;
    background: #fff;
    padding: 20px 10px;
    padding-bottom: 23px;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    color: #EB5757;
}

.bid-card-item-single .c-header .button-1 {
    width: 152px;
    border: none;
    padding: 7px 0px;
    font-size: 11px;
    border-radius: 2px;
    background: rgb(93 120 255 / 18%);
    color: rgb(93 120 255);
    transition: all .3s ease;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 8px;
    position: absolute;
    top: 13px;
    right: 12px;
    line-height: 18px;
}

.bid-card-item-single .c-header .button-1.fav_active {
    background: rgb(242 134 133);
    color: #fff;
}

.bid-card-item-single .c-header .button-1:hover {
    color: #fff;
    background: rgb(93 120 255);
}
.bid-card-item-single .c-header .button-1.fav_active:hover {
    background: rgb(242 134 133);
    color: #fff;
}
.pagination-component-wrapper hr {
    display: none;
}
.Toastify__toast-container {
    z-index: 99999;
    -webkit-transform: transla
}
.bid-card-item-single .bid-card-list {
    padding: 0px 20px;
}

.bid-card-item-single .bid-card-list ul li {
    display: inline-block;
    color: #646c9a;
    font-size: 14px;
    margin-right: 20px;
    font-weight: 500;
}

.bid-card-item-single .bid-card-list ul li:last-child {
    margin-right: 0px;
}

.bid-card-item-single .bid-card-list ul li i {
    color: var(--primary-color);
    margin-right: 2px;
}

.bid-card-item-single .bid-card-list ul li i.fa.fa-link {
    color: #0abb87;
}

.bid-card-item-single .bid-card-list ul li i.fa.fa-clock-o {
    color: #0abb87;
}

.bid-card-item-single .bid-footer ul li:nth-child(2) .icon {
    -webkit-text-stroke-color: var(--primary-color);
}

.bid-card-item-single .bid-footer ul li:nth-child(3) .icon {
    -webkit-text-stroke-color: #fd397a;
}

.bid-card-item-single .bid-footer ul li:nth-child(4) .icon {
    -webkit-text-stroke-color: #0abb87;
    font-size: 27px !important;
}
.bid-card-item-single .bid-footer ul li .icon.status {
    -webkit-text-stroke-color: #304463;
}
.bid-details-bottom .multiselect-container .search-wrapper span {
    background: var(--primary-color);
    border-radius: 2px;
}
.row.profile {
    padding-bottom: 70px;
}
#root {
	min-height: 100vh;
	position: relative;
	padding-bottom: 41px;
}
footer#footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.mr-10{
    margin-right:10px;
}
.multiselect-container ul.optionContainer li:hover, .multiselect-container ul.optionContainer .highlight {
    background-color: #8282821f;
    color: #000;
}
.multiselect-container ul.optionContainer {
    border: 1px solid #ddd;
    border-radius: 0px;
}
.bid-card-item-single .c-header .button-1 i.fa.fa-circle-o-notch.fa-spin {
    font-size: 12px;
    line-height: 1;
}
.go-back-h-text{
    margin-top: 10px;
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #828282;
}
.go-back-h-text a{
    color: #013a6d;
    font-weight: 700;
}
.go-back-h-text a:hover{
    color: #013a6d;
}
.profile-page.profile-page-style .tabs {
	scrollbar-width: inherit !important;
}
.no-bid-found-data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    height: 200px;
    color: #eb57578f;
}
.header-right-menu-bar a.dropdown-toggle.profile-img img.img-circle {
    border: 1px solid #173cf6 !important;
}
.multi_select_block.custom_tooltip .custom_tooltip_content {
    top: 20px;
}
@media (max-width: 1199px) {
    .bid-card-item-single .bid-footer ul {
        display: inherit;
        width: 100%;
    }

    .bid-card-item-single .bid-footer ul li {
        margin-right: 4%;
        width: 46%;
        overflow: hidden;
        margin-top: 13px;
        float: left;
    }
}

@media (max-width:991px) {
    .bid-card-item-single .bid-footer ul li {
        margin-right: 0%;
        width: 25%;
        margin-top: 0px;
    }
}

@media (max-width:850px) {
    .bid-card-item-single .bid-footer ul li {
        margin-right: 2%;
        width: 48%;
        margin-top: 15px;
    }
}

@media (max-width:576px) {
    .bid-card-item-single .bid-footer ul li {
        margin-right: 0%;
        width: 100%;
        margin-top: 15px;
    }

    .bid-card-item-single .c-header {
        display: inherit;
    }

    .bid-card-item-single .c-header h4 {
        margin-bottom: 10px;
    }

    .bid-card-item-single .c-header h4 {
        padding-right: 0px;
        padding-bottom: 2px;
    }

    .bid-card-item-single .c-header button.bookmark-btn {
        position: relative;
        top: 0;
        right: 0;
        margin-bottom: 10px;
    }

    .bid-card-item-single .c-header .button-1 {
        position: relative;
        top: 0;
        right: 0;
        margin-bottom: 10px !important;
    }
    .header-nav.main-header-area.container {
        padding-right: 10px;
    }
    
}