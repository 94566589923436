.btn-section {
    .button-1 {
        width: auto;
        height: auto;
        padding: 6px;
        background: #013a6d;
        border: none;
        color: #fff;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
        border-radius: 6px;
        transition: all .4s ease-in-out;
        display: inline-block;
    }

    .save-button {
        width: 100%;
        height: auto;
        padding: 10px;
        background-color: #28a745 !important;
        border-color: #28a745 !important;
    }

}

.apply_bid {
    .search-wrapper {
        span {
            background-color: transparent !important;
        }

        .icon_cancel {
            display: none;
        }
    }
}