.count_down {
    .countdown-wrapper {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        background: #2d69f9;
        padding: 10px 0px;
    }

    .countdown-wrapper .time-section {
        margin: 0px 18px;
    }

    .countdown-wrapper .time-section .time {
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 5px;
        color: #fff;
    }

    .countdown-wrapper .time-section small.time-text {
        color: #fff;
        font-weight: 600;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .coming-soon-text {
        text-align: center;
        background: #000;
    }

    .coming-soon-text h2 {
        color: #fff;
        font-size: 30px;
        padding: 20px 0px;
        text-transform: capitalize;
        font-weight: 900;
    }

    .countdown-wrapper-full {
        background: #2d69f9;
    }

    .countdown-wrapper-full .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
    }

    .coming-soon-text-l h3 {
        font-size: 31px;
        color: #fff;
        text-transform: capitalize;
        font-weight: 700;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .site-logo-inner {
        width: 200px;

        padding-right: 20px;
    }
}


@media (max-width:995px) {
    .count_down {
        .coming-soon-text-l h3 {
            font-size: 18px;
            display: flex;
        }

        .countdown-wrapper .time-section .time {
            font-size: 30px;
        }

        .countdown-wrapper .time-section small.time-text {
            font-size: 13px;
        }

        .site-logo-inner {
            width: 168px;
            margin-right: 20px;
            display: inline-block;
        }
    }
}

@media (max-width:767px) {
    .count_down {
        .coming-soon-text-l h3 {
            font-size: 18px;
        }

        .countdown-wrapper .time-section .time {
            font-size: 30px;
        }

        .countdown-wrapper .time-section small.time-text {
            font-size: 10px;
        }

        .countdown-wrapper .time-section {
            margin: 0px 10px;
        }

        .site-logo-main {
            margin: 10px 0px;
            width: 100%;
            text-align: center;
        }

        .countdown-wrapper-full .container {

            padding: 0px;
        }

        .site-logo-inner {
            margin-right: 0px;
        }
    }
}

@media (max-width:576px) {
    .count_down {
        .countdown-wrapper-full .container {
            display: inline-block;
        }

        .countdown-wrapper .time-section {
            margin: 0px 10px;
        }

        .coming-soon-text-l h3 {
            font-size: 16px;
        }

        .countdown-wrapper .time-section .time {
            font-size: 25px;
        }

        .countdown-wrapper .time-section small.time-text {
            font-size: 9px;
        }

        .countdown-wrapper {
            padding: 10px 20px;
        }
    }
}

@media (max-width:450px) {
    .count_down {
        .coming-soon-text-l h3 {
            font-size: 14px;
        }

        .countdown-wrapper .time-section .time {
            font-size: 20px;
        }

        .countdown-wrapper .time-section small.time-text {
            font-size: 9px;
        }

        .countdown-wrapper .time-section {
            margin: 0px 8px;
        }
    }
}