.footer-sticky {
    z-index: 1024;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #fafafa;
    display: none;

    .footer-div {
        height: 50px;
        padding: 10px;

        &:hover {
            background-color: #f5f5f5;
        }

        .footer-navbar-title {
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            text-align: center;
            text-transform: uppercase;
            color: #4A4A4A;
            opacity: 0.5;
            padding-top: 8px;

        }

        .active-title {
            color: #333333 !important;
            opacity: 1 !important;
            font-weight: bold;
            font-family: Mulish;
            font-style: normal;
            font-size: 10px;
            text-align: center;
            text-transform: uppercase;
            padding-top: 8px;
        }
    }
}