.w_h_100 {
    width: 100% !important;
    height: 100% !important;

    .error_boundary {
        min-height: 270px;
        min-width: 290px;
        width: 60%;
        height: 60%;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text_gray {
        color: rgb(100, 100, 100);
    }
}

.h_100vh {
    height: 100vh;
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
    .error_boundary {
        height: 80%;
        min-width: 80%;
    }
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
    .error_boundary {
        height: 80%;
        min-width: 95%;
    }
}