.footer-container {
    height: auto;

    .footer-banner {
        background: #243C6E;
        //background: #013a6d;
        //text-align: center;
        position: relative;
        padding: 55px 0;

        h1 {
            font-size: 37px;
            font-weight: 900;
            color: #ffffff;
            margin: 0;
            text-align: center;
        }

        p {
            color: #f2f2f2;
            margin-top: 10px;
            text-align: center;
        }

        .footer_image {
            position: absolute;
            top: 0;
            margin-left: 150px;
            width: 230px;
        }
    }

    #footer .footer-top {
        padding: 58px 0 30px 0;
    }

    .footer-top h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        color: #4F4F4F;
        position: relative;
        padding-bottom: 12px;
    }

    #footer .footer-top .footer-links ul a {
        line-height: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }

    .social-links a {
        padding: 5px 0;
        margin-right: 14px;
        width: 27px;
        height: 27px;
    }

    .career {
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 15px !important;
        text-decoration-line: underline !important;
        color: #4F4F4F !important;
    }

    .facebook {
        background: #000000 !important;
    }

    .linkedin {
        background: #007AB9 !important;
    }

    .twitter {
        background: #55ACEE !important;
    }
    
    .youtube {
        background-color: red;
    }
    .footer-bottom {
        background: var(--primary-color);
        padding: 10px 0;
        overflow: hidden;

        .footer-bottom-inner {
            font-size: 15px;
            color: #FFFFFF;

            .footer-left {
                text-align: left;
            }

            .footer-right {
                text-align: right;
                float: right;
            }
        }
    }
}
