@import './variables.scss';

/**
* Template Name: Bid advisor
* Template URL:

*/


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html {
    scroll-behavior: initial;
}

body {
    font-family: 'Lato', sans-serif;
    word-break: break-word;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 100vw;
        margin-block: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #AAAAAA;
        border-radius: 100vw;
    }
}

.pb-100 {
    padding-bottom: 100px !important;
}

.fs_18 {
    font-size: 18px !important;
}

.label_name_text {
    color: #3b3b3b;
    font-weight: bold;
    font-size: 16px;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif;
    margin: 0;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

iframe {
    border: none;
}

.Toastify__toast-container {
    width: 350px;
}

.Toastify__toast-container--top-right {
    top: 1em;
    right: 0;
}

.no-wrap {
    white-space: nowrap;
}

.asterisk:after {
    content: " * ";
    color: red;
    font-weight: bold;
}

.float_right {
    float: right;
}

.w_break_normal {
    word-break: normal;
}

#header {
    background: #FAFAFA;
}

.container-header {
    max-width: 90%;
    margin: 0px auto;
    display: table;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    padding: 5px 0px;
    margin: 0px;
    font-size: 1rem;
    color: #013A6D;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 10px 26px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 10px 26px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 10px 26px 0px rgba(50, 50, 50, 0.75);
}

.dropdown-menu li {
    padding: 2px 10px 3px;
}

.dropdown-menu li:first-child {
    padding-top: 0px;
    border-radius: 4px 4px 0px 0px;
}

.dropdown-menu li:last-child {
    padding-bottom: 0px;
    border-radius: 0px 0px 4px 4px;

}

.dropdown-menu>li>a {
    text-transform: uppercase;
    color: #243c6e;
    line-height: 26px;
    font-size: 12px;
    font-weight: 600;
    display: block;
}

.dropdown-menu>li:hover>a {
    color: #243c6e;
}

.dropdown-menu.show {
    right: 0;
    left: auto;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 3px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dropdown-menu .divider:hover {
    background-color: #e5e5e5;

}

.course-details h3:after {
    display: none;
    height: 0px;
}

/* Start Header section*/

/*Header*/
.dropdown-toggle::after {
    display: none;
}

.navbar {
    background: var(--primary-color);
    padding: 10px 0px;
}

.header-nav {
    margin: 5px auto;
}

.navbar-default {
    background: #FAFAFA none repeat scroll 0 0;
    width: 100%;
}


.logo-site {
    float: left;
    margin: 0px;
    width: 202px;
    padding: 0px;
}

.logo-site a {
    margin: 0;
    float: left;
}

.logo-site img {
    //width: 210px;
    width: 100%;
}

.auth-slogan {
    display: block;
    font-size: 12px;
    color: #f57c00;
    text-align: right;
    //font-weight: 600;
    font-family: 'Poppins';
}

.navbar-collapse {
    float: right;
}

.navbar-top {
    margin-right: 0px;
    margin-top: 2px;
}

.navbar-middle {
    margin-right: 0px;
    text-transform: uppercase;
}

.navbar-middle .navbar-nav {
    width: 100%;
    text-align: right;
    display: inline-block;
    padding: 0 12px;
    font-size: 14px;
    color: #013A6D;
    margin-top: 5px;
}

.navbar-middle .navbar-nav li {
    display: inline-block;
}


// glogal common all general
.fs_1rem {
    font-size: 1.1rem;
}

.me-3 {
    margin-right: 15px !important;
}

.pointer_events_none {
    pointer-events: none;
}

.placeholder_at_center {
    input {
        text-align: center;
    }
}

.text_gray {
    color: #333333;
}


.top-nav>li {
    display: inline-block;
    float: left;
}

.top-nav>li>a {
    color: #fff;
    font-size: 13px;
    padding: 0 10px;
}

.top-nav>li:last-child>a {
    padding-right: 0;
}

.top-nav>li span.badge {
    background: #f57c00 none repeat scroll 0 0;
    border: 1px solid #FFFFFF;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    position: absolute;
    right: 4px;
    top: -5px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 4px;
}

.dropdown-notification {
    .dropdown-toggle {
        &:hover {
            color: #FFFFFF;
        }
    }
}

.scrollable {
    overflow: hidden;
    overflow-x: scroll;
}

.navbar-right>li:last-child a {
    padding-right: 0px;

}

.top-nav>li>a>img {
    border: 1px solid #C4C4C4;
    width: 35px !important;
    height: 35px;
    border-radius: 50%;
    background: #fff;
}

.top-nav>.dropdown-notification {
    margin-top: 7px;
}

.navbar-default .navbar-nav>li>a {
    color: #013A6D;
    text-transform: uppercase;
}

.navbar-default .navbar-nav>li>a:hover {
    color: #333333;
}

.bg_blue {
    background-color: #013a6d !important;
}

.color_white {
    color: white;
}

.delete_div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E03232;
    padding: 10px;
    color: white;
    border-radius: 5px;
    max-width: 35px;
    max-height: 42px;
    cursor: pointer;
}

.ash_color {
    color: #4F4F4F
}

.color_white:hover {
    color: white;
}

.dropdown-notification i {
    color: #C4C4C4;
    font-size: 22px;
}

.dropdown-notification .dropdown-menu {
    width: 275px;
    max-width: 275px;
    min-width: 160px;
}

.dropdown-notification>a {
    font-size: 18px !important;
    line-height: 29px !important;
}

.top-nav .dropdown-menu .dropdown-menu-list {
    list-style: outside none none;
    padding-left: 0;
    padding-right: 0 !important;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external {
    border-radius: 4px 4px 0 0;
    display: block;
    letter-spacing: 0.5px;
    overflow: hidden;
    padding: 15px;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external>h3 {
    display: inline-block;
    float: left;
    font-size: 13px;
    margin: 0;
    padding: 0;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external>a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    color: #888;
    clear: inherit;
    display: inline-block;
    font-size: 13px;
    margin-top: -5px;
    padding: 0;
    position: absolute;
    right: 10px;
    font-weight: 600;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external {
    background: #eaedf2 none repeat scroll 0 0;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list {
    list-style: outside none none;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a {
    clear: both;
    display: block;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    padding: 16px 15px 18px;
    text-shadow: none;
    white-space: normal;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .time {
    float: right;
    font-size: 11px;
    font-weight: 400;
    max-width: 75px;
    opacity: 0.7;
    padding: 1px 5px;
    text-align: right;
    background: #f1f1f1 none repeat scroll 0 0;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details {
    font-size: 13px;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details i {
    color: #fff;
    font-size: 13px;

}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon {
    border-radius: 50%;
    padding: 10px;
    margin-right: 10px;
    text-decoration: none;
    text-align: center;
    width: 20px;
    height: 20px;
}

.top-nav>li>a:hover,
.top-nav>li>a:focus,
.top-nav>.open>a,
.top-nav>.open>a:hover,
.top-nav>.open>a:focus {
    background-color: transparent;
    color: #999999;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a:hover {
    background: #F2F2F2 none repeat scroll 0 0;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    background-color: transparent;
    color: #ca3f02;
}

.dropdown-notification .dropdown-menu li {
    padding: 0px;
}


/*Toggle Navigation design*/
.navbar-default .navbar-toggle {
    border: none;
}

.width_100 {
    width: 100%;
}

.desc_length {
    right: 30px;
    bottom: -13px;
}

.text_area_none {
    resize: none;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: none;
}


/*Search form*/
*:focus {
    outline: 0 !important;
}

.search-container {
    float: left;
    border-radius: 30px;
    background: #FFFFFF;
    padding: 0;
    margin-top: 4px;
    margin-right: 12px;
}

.search-container .header-search {
    border: 0;
    color: #4F4F4F;
    font-size: 14px;
    padding: 4px 10px 6px 35px !important;
    background-image: url("../assets/img/Search.svg");
    background-repeat: no-repeat;
    background-position: 8px 6px;
    border-radius: 30px;
    width: 100%; // 250px
}

.search-container .header-search::placeholder {
    color: #4F4F4F;
}

.search-container input:focus {
    border: 0;
}

.profile .sidebar-right {
    margin-top: 50px;
}

.profile-sidebar-main {
    padding: 0px;
}

.profile-sidebar-bio {
    background: #fff;
    border: 1px solid #F2F2F2;
    border-radius: 3px;
    margin-top: -90px;
    z-index: 99;
    position: relative;
}

.profile-userpic-bio {
    text-align: center;
    padding: 20px 0 5px 0;
}

.profile-userpic-bio img {
    float: none;
    margin: 0 auto;
    width: 165px;
    height: 165px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;

}

.profile-nav-bio {
    padding: 5px 8px 3px;
    background: #F2F2F2;
    color: #013A6D;
    width: 124px;
    margin: 10px auto;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
    text-align: center;
}

.profile-usertitle-bio {
    text-align: center;
    width: 100%;
    color: #828282;

}

.profile-usertitle-job-bio span {
    font-size: 11px;
    text-align: center;
    width: 100%;
    display: block;
    padding: 0px 20px 2px;
}

.profile-sidebar-user-info p {
    font-size: 11px;
    padding: 20px 15px 0px;
    line-height: 18px;
    margin-bottom: 3px;
}

.profile-sidebar-user-info p a {
    text-decoration: underline !important;
}

.profile-sidebar-user-url {
    font-size: 11px;
    font-weight: 700;
    color: #4F4F4F;
    display: inline-block;
    margin: 12px 0px 7px;
}

.profile-sidebar-url-inner .share-button {
    background: #2F80ED;
    border-radius: 18px;
    width: 21px;
    height: 21px;
    float: left;
    margin-top: -2px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

}

.profile-sidebar-url-inner .web-url {
    margin: 0px 7px 5px;
    color: #4F4F4F;
    word-break: break-word;
    display: block;
    float: left;
}

.profile-sidebar-url-inner {
    display: inline-block;
    border-radius: 20px;
    padding: 6px 4px 2px 7px;
    min-height: 20px;
}

.profile-sidebar-user-url i {
    font-size: 16px;
    margin-top: -1px;
    float: left;
}

.profile-sidebar-url-inner .share-button i {
    margin-top: 4px;
    margin-left: 4px;
    font-size: 13px;
}

.profile-sidebar-url-inner:hover {
    background: #F2F2F2;
    color: #2F80ED;
}

.profile-sidebar-url-inner:hover a {
    color: #2F80ED;

}

.profile-sidebar-url-inner:hover .share-button {
    color: #fff;
    visibility: visible;
    opacity: 1;
}

.profile-sidebar-user-bottom {
    width: 100%;
    margin: 0px;
    display: inline-block;
}

.profile-sidebar-user-total-count {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 3px 0;
}

.profile-sidebar-user-total-count span {
    width: 100%;
    display: inline-block;
    float: left;
    line-height: 18px;
}

.profile-sidebar-user-total-count .title {
    font-size: 12px;
}

.profile-sidebar-user-total-count .counter {
    font-size: 14px;
    font-weight: 700;
    color: #4F4F4F;
}

.profile-sidebar-user-contact {
    width: 100%;
    margin: 20px 0px 10px;
}

.profile-sidebar-user-contact a {
    background: #F2F2F2;
    color: #4F4F4F;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
    text-align: center;
    padding: 6px;
    width: 38%;
    float: left;
    margin: 0px 10%;
}

.profile-sidebar-user-contact a:first-child {
    margin-right: 2%;
}

.profile-sidebar-user-contact a:last-child {
    margin-left: 2%;
}

.profile-sidebar-user-social-share {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 22px 0px;
}

.profile-sidebar-user-social-share a {
    width: 26px;
    height: 26px;
    padding: 6px 0;
    font-size: 14px;
}

.profile-sidebar-user-social-share .facebook {
    background: #000000;
}

.profile-sidebar-user-social-share .twitter {
    background: #55ACEE;
}

.profile-sidebar-user-social-share .instagram {
    background: #007AB9;

}

.profile-sidebar-user-bottom-text {
    margin: 10px 0px;
}

.profile-sidebar-user-bottom-text span {
    font-size: 12px;
    color: #BDBDBD;
    padding: 10px 0px;
    display: block;
    border-top: 1px solid #E0E0E0;
    width: 75%;
    margin: 0px auto;
}

/* Course Detials page*/
/** ======================== **/
/* Course List sidebar*/

.course-details .course-list-title {
    font-size: 14px;
    font-weight: 600;
    color: #4F4F4F;
    padding: 0px;
    border: 0px;
    position: unset;
    margin: 10px 0px;

}

.course-item .accordion-item {
    border: 0px;

}

.course-item .accordion-button {
    border-radius: 3px;
    background: #828282 !important;
    color: #F2F2F2;
    padding: 12px;
    margin-bottom: 0px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
}

.bg_transparent {
    background-color: transparent !important;
}

.accordion-item .collapsed {
    background: #F2F2F2 !important;
    color: #4F4F4F;
}

.arrow-left {
    width: 15px;
    height: 15px;
    float: left;
}

.arrow-left i {
    font-size: 20px;
    font-weight: 600;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' stroke='%23ffffff' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");

    color: red;
    top: 9px;
    margin-left: -5px;

}

.accordion-button::after {

    margin-left: 0px;
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' fill='%23212529'%3e%3cpath fill-rule='evenodd' stroke='%23212529' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    transition: transform .2s ease-in-out;
    background-position: center;
    top: 15px;
}

.accordion-button .accor-duration {
    color: #BDBDBD;
    width: 90%;
    float: left;
    margin-left: 10px;
    font-size: 11px;
    font-weight: 400;

}

.accordion-button .accor-duration strong {
    color: #F2F2F2;
    font-weight: 600;
    font-size: 14px;
}

.accordion-button .accor-duration b {
    font-weight: 700;
    color: #F2F2F2;
    margin-left: 4px;
}

.accordion-button .accor-duration i {
    display: none;
}

.collapsed .accor-duration {
    color: #828282;
}

.collapsed .accor-duration i {
    float: right;
    font-size: 14px;
    color: #4F4F4F;
    display: block;
}

.collapsed .accor-duration strong {
    color: #4F4F4F;
    font-weight: 600;

}

.collapsed .accor-duration b {
    color: #4F4F4F;
}

.course-item .accordion-body {
    padding: 0px;
}

.course-item .list-group-item {
    border: 0px;
    color: #4F4F4F;
    font-weight: 300;
    font-size: 12px;
}

.course-item ul,
.course-item li {
    list-style: none;
}

.course-item ul li {
    font-size: 12px;
    color: #4F4F4F;
    border-bottom: 1px solid #F2F2F2;
    line-height: 23px;
    padding: 10px 0px;
}

.list-group-item {
    padding: 0px 15px;
}

.course-item ul li span {
    float: right;
    padding-right: 20px;
    color: #BDBDBD;
    font-weight: 300;
}

.course-item ul li i {
    float: left;
    margin-left: 12px;
    margin-top: 5px;
    font-size: 15px;
}

.course-item .list-group-item {
    float: left;
}

.multiselect-container {
    .optionListContainer {
        .optionContainer {
            input {
                margin-right: 7px;
            }
        }
    }
}

/** Course detials section end*/


/* Start Profile Section Start */
/*--------------------------------------------------------------
# profile-cover Section
--------------------------------------------------------------*/
#profile-cover {
    width: 100%;
    height: 185px;
    background-size: cover;
    position: relative;
    background-position: center;
    padding: 0;

}

#profile-cover .container {
    padding-top: 72px;
}

#profile-cover .container .row {
    display: block;
}



@media (max-width: 992px) {
    #profile-cover .container {
        padding-top: 62px;
    }
}

.cover-profile li:first-child {
    margin-left: 0px;
    padding-left: 0px;
}

.cover-profile li:last-child {
    margin-right: 0px;
    padding-right: 0px;
}

/*Cover*/

.cover-nav {
    float: right;
    padding: 10px;
    margin-top: 120px;
}

.cover-profile {
    width: 100%;
    float: right;
    display: inline-block;
    text-align: right;
}

.cover-profile li {
    background: #F2F2F2;
    color: #013A6D;
    width: 124px;
    margin: 0px;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 30px;
    text-align: center;
    margin-left: 15px;
    display: inline-block;
}

.cover-profile>li>a {
    padding: 4px 7px 3px 13px;
    width: 100%;
    display: inline-block;

}

.cover-profile .cover-button>a {
    width: 100%;
    padding: 6px;
    text-align: center;
    display: inline-block;
    font-weight: 600;
}

.cover-profile>li>a span {
    font-size: 12px;
    font-weight: 600;
    padding: 2px;
    float: left;
    margin-right: 7px;
}

.cover-profile>li>a i {
    font-size: 19px;
    font-weight: 700;
    padding: 0px;
}

.cover-profile>li>a:hover,
.cover-profile>li>a:focus {
    background-color: #F2F2F2;
    border-radius: 30px;
}

.cover-profile .open>a,
.cover-profile .open>a:hover,
.cover-profile .open>a:focus {
    border-color: #F2F2F2;
    border-radius: 30px;

}

.cover-profile .dropdown-menu>li>a {
    text-transform: uppercase;
    color: #013A6D;
    line-height: 26px;
}

/* Profile container */


/* Profile sidebar */
.position-stick {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.profile-sidebar {
    background: #fff;
    border: 1px solid #F2F2F2;
    border-radius: 3px;
    margin-top: 50px;
    z-index: 99;
    position: relative;
    box-shadow: 0px 3px 10px 0px #ddd;
    position: sticky;
    top: 16px;
}

.profile-userpic {
    text-align: center;
    background: #FAFAFA;
    padding: 20px 0 5px 0;
    display: flex;
    justify-content: center;
}

.profile-userpic .profile-userpic-inner {
    position: relative;
    width: 165px;
    height: 165px;
}

.profile-userpic img {
    float: none;
    margin: 0 auto;
    width: 165px;
    height: 165px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #ebebeb;

}

#file-input-image {
    opacity: 0;
    cursor: pointer;
    width: 22px;
    height: 22px;
    font-size: 0;
    margin-top: -4px;
    margin-left: -4px;
    position: absolute;
}

.user-circle-lg {
    height: 80px;
    width: 80px;
    background: none;
    display: inline-block;
    position: relative;
}

.edit-circle-white {
    height: 22px;
    width: 22px;
    position: absolute;
    right: 0;
    top: 104px;
    display: block;
    background: white;
    border-radius: 50%;
    -webkit-box-shadow: 5px 5px 7px 0px rgba(50, 50, 50, 0.62);
    -moz-box-shadow: 5px 5px 7px 0px rgba(50, 50, 50, 0.62);
    box-shadow: 5px 5px 7px 0px rgba(50, 50, 50, 0.62);
}

.edit-circle-white:hover {
    background: white;
    /* border:1px solid #fff; */
}

.user-info-edit {
    height: 15px;
    width: 15px;
    display: block;
    position: absolute;
    cursor: pointer;
    background: url("../assets/img/prf_edit.svg") center;
    background-size: cover;
    margin-top: 4px;
    margin-left: 4px;
}


.profile-usertitle {
    text-align: center;
    padding-top: 20px;
    background: #FAFAFA;
    padding: 4px 0px;
    border-bottom: 1px solid #F2F2F2;

}

.profile-usertitle-name {
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
}

.profile-usertitle-job {
    text-transform: uppercase;
    color: #5b9bd1;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
}

.profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}

.profile-userbuttons .btn {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 15px;
    margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
    margin-right: 0px;
}

.side-nav {
    list-style: none;
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.profile-usermenu ul li {
    border-bottom: 1px solid #F2F2F2;
}

.profile-usermenu ul li:last-child {
    border-bottom: none;
}

.profile-usermenu ul li a {
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    padding: 10px 0px;
    display: block;
}

.profile-usermenu ul li a i {
    margin-right: 8px;
    font-size: 14px;
}

.profile-usermenu ul li a:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.multiselect-container .search-wrapper span.chip {
    border-radius: 3px;
}

.profile-usermenu ul li a:hover {
    color: #fff;
}

.profile-usermenu ul li.active {
    border-bottom: none;
    background: #828282;
}

.profile-usermenu ul li.active a {
    color: #fff;
}

.profile-usermenu ul li span {
    font-size: 12px;
    color: #BDBDBD;
    padding: 10px 0px;
    display: block;
}

/* Profile Content */
.profile-content {
    padding: 20px;
    background: #fff;
    min-height: 460px;
}

.profile-nav {
    width: 100%;
    background: #FAFAFA;
    padding: 10px 0px;
}

.navbar-profile {
    background: #F2F2F2;
    color: #013A6D;
    width: 124px;
    margin: 0px auto;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 30px;
    text-align: center;
}


.navbar-profile>li>a {
    padding: 4px 7px 3px 13px;
    width: 100%;
    display: inline-block;
}

.navbar-profile>li>a span {
    font-size: 12px;
    font-weight: 600;
    padding: 2px;
    float: left;
    margin-right: 7px;
}

.navbar-profile>li>a i {
    font-size: 19px;
    font-weight: 700;
    padding: 0px;
}

.navbar-profile:hover,
.navbar-profile>li>a:hover,
.navbar-profile>li>a:focus {
    background-color: #F2F2F2;
    border-radius: 30px;
}

.navbar-profile .open>a,
.navbar-profile .open>a:hover,
.navbar-profile .open>a:focus {
    background-color: #F2F2F2;
    border-color: #F2F2F2;
    border-radius: 30px;

}

.navbar-profile .dropdown-menu>li>a {
    text-transform: uppercase;
    color: #013A6D;
    line-height: 26px;
    display: block;
}


/* Slogan Sectiopns*/

.bottom-slogan {
    width: 100%;
    background: #013A6D;
    background: #013A6D url("../assets/img/bottom-slogan.png") center;
    background-size: cover;
}

.start-learning-section {
    text-align: center;
    padding: 44px 20px;
}

.start-learning-section h2 {
    font-size: 35px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: uppercase;
    line-height: 30px;
    width: 100%;
}

.start-learning-section span {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: #F2F2F2;
    width: 100%;

}

#footer {
    background: #F2F2F2;
}

#footer .footer-top h4 {
    color: #4F4F4F;
    font-weight: 600;
}

#footer .footer-top .footer-links ul a {
    color: #243c6e;
}

.footer-links {
    width: 20%;
}

.footer-left {
    float: left;
    text-align: left;
}

.footer-right {
    float: right;
    text-align: right;
}

.footer-bottom {
    background: #E0E0E0;
    padding: 10px 0px;
    color: #4F4F4F;
    display: block;
}


.right {
    float: right;
}

.accordion-button .fa-angle-down::before {
    content: "\f106" !important;
    color: #ffffff;
}

.accordion-button.collapsed .fa-angle-down::before {
    content: "\f107" !important;
    color: #4F4F4F;
}

input {
    font-size: 14px;
}

input::placeholder,
textarea::placeholder {
    color: #4F4F4F;
    font-size: 12px;
}

.modal-content {
    border-radius: 0;
}

.modal-content .modal-header {
    padding: 16px 30px !important;
}


.modal-content .modal-title {
    line-height: 1.2;
}

.modal-content .close {
    width: 28px;
    height: 28px;
    background: #6c7275 !important;
    border-radius: 50%;
    margin-right: 0 !important;
    color: #fff !important;
    text-shadow: none !important;
    opacity: 1 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    font-weight: normal !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ml_price {
    font-family: 'Mulish', sans-serif;
}

.display_none {
    display: none;
}

.assistive_txt {
    font-style: italic;
    font-size: 13px;
    color: darkcyan;
}

.assistive_txt .assistive_txt_size {
    font-size: 16px;
}

.landing-layout-main {
    .footer-container {
        margin-top: 0 !important;
    }
}

.course-layout-main {
    .footer-container {
        margin-top: 42px !important;
    }
}

.p-all-138 {
    margin: 0 138px !important;
}

.footer-navbar-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    color: #4A4A4A;
    opacity: 0.5;
    padding-top: 8px;
}

.footer-sticky {
    z-index: 1024;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #fafafa;
    display: none;
}

.active-class-bg {
    background-color: #f5f5f5;

    h5 {
        color: #333333 !important;
        opacity: 1 !important;
        font-weight: bold;
    }
}

.footer-div {
    height: 50px;
    padding: 0 5px;

    &:hover {
        background-color: #f5f5f5;
    }
}


//Old Code .....................................................................................

button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.disabled {
    pointer-events: none;
    cursor: default;
    background: transparent !important;
    border: none;
    opacity: 0.5;
}

.disabled_sending {
    pointer-events: none;
    cursor: default;
    background: transparent !important;
    border: none;
    opacity: 0.5;
}

a:hover {
    cursor: pointer;
}

.button {
    width: 186.99px;
    height: 45px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #013a6d;
    background: #f2f2f2;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #bdbdbd;
}

.review_button {
    width: 106.87px;
    height: 31.29px;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: #013a6d;
    border: 1px solid #bdbdbd;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.forgot-timer {
    font-size: 12px;
}

.social_icon {
    color: $white;
    font-size: 16px;
}

.fa-spin {
    animation: fa-spin 0.75s infinite linear;
}

.mr-5px {
    margin-right: 5px;
}

.ml-20px {
    margin-left: 20px !important;
}

@media screen and (max-width: 991.98px) {
    //.footer-links {
    //    flex: 0 0 25%;
    //    max-width: 25%;
    //}

    /* Header Section */
    .navbar-dark .navbar-toggler {
        color: rgba(1, 58, 109, 1);
        //border-color: rgba(1, 58, 109, 1);
        //margin: 5px 0px;
        float: right;
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }


    .logo-site {
        display: block;
        float: none;
    }

    .navbar-expand-lg .navbar-collapse {
        //margin-top: 10px;
        width: 100%;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        float: none;
    }

    /* Profile Cover Section */
    .cover-nav {
        margin-top: 131px;
    }

    /* Footer Section */
    #footer .footer-top {
        margin: 0;

    }

    #footer .footer-top .footer-links {
        margin-bottom: 30px;
        text-align: center;
    }

    #footer .footer-top .footer-links ul li {
        display: block;
    }

    .social-links {
        text-align: center !important;
    }

}

@media (min-width: 320px) and (max-width: 991.98px) {
    .navbar-dark .navbar-toggler {
        display: none;
    }

    .dropdown-menu.show {
        right: 0 !important;
        //right: -15px !important;
        top: 40px !important;
    }

    .navbar {
        .header-nav {
            display: flex;
            align-items: center;

            .collapse:not(.show) {
                //display: block;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                #bs-example-navbar-collapse-1\ home,
                #bs-example-navbar-collapse-1\ course,
                div#bs-example-navbar-collapse-1\ about-us,
                div#bs-example-navbar-collapse-1\ contact-us,
                .search-container.navbar-top.search-top {
                    display: none !important;
                }

                ul.nav.navbar-nav.pull-right.top-nav.navbar-top {
                    //display: contents;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    margin-top: 0;
                    padding: 0 0;
                    margin-bottom: 0 !important;

                    .search-top-bar {
                        display: none !important;

                        .btn-secondary {
                            background-color: transparent !important;
                            border-color: #243c6e !important;
                            border-radius: 100px;
                            padding: 6px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: none;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        .dropdown-menu {
                            border-radius: 4px;
                            width: 200px;
                            //height: 50px;
                            right: 0 !important;

                            .search-container {
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-expand-md .navbar-collapse {
        margin-top: 0 !important;
    }
}


@media screen and (max-width: 767px) {
    .profile-page {
        #main {
            .main-body-container {
                .sidebar-right {
                    .profile-container {
                        .tabs {
                            &::-webkit-scrollbar {
                                display: none;
                            }

                            overflow: -moz-scrollbars-none;
                            -ms-overflow-style: none;
                        }

                        .my-profile-content {
                            form {
                                .content_group_double {
                                    flex-direction: column !important;
                                    align-items: flex-start !important;

                                    .input-block {
                                        width: 100% !important;

                                        .input.input-box.input {
                                            border-right: 1px solid #E0E0E0;
                                            border-left: 1px solid #E0E0E0;
                                        }
                                    }

                                    .multi_select_block {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .navbar-middle {
        float: left;
    }

    .navbar-expand-lg .navbar-collapse {
        width: 100%;
    }

    .navbar-top {
        display: inline-block;
        //margin-top: 10px;
    }

    .navbar-middle {
        display: table !important;
        //float: right;
        margin-top: 3px !important;
        //margin-left: 10px;
    }

    .search-container {
        margin-top: 0px;
    }

    .navbar-middle .navbar-nav {
        padding: 0px;
    }

    .navbar-middle .navbar-nav li {
        margin-right: 10px;
    }

    .search-container input {
        padding: 0px 5px 5px;
        width: 180px;
        font-size: 12px;
    }

    .search-container button {
        font-size: 14px;
    }

    .top-nav>li>a {
        padding: 10px 6px 5px;
    }

    /* Start for profile Cover*/
    .cover-profile li {
        width: 115px;
        font-size: 11px;
        margin-left: 10px;
    }

    .cover-profile>li>a {
        padding: 3px 5px 3px 12px;
    }

    .cover-profile .cover-button>a {
        padding: 5px;
    }

    .cover-profile>li>a span {
        font-size: 11px;
    }


    /** Left side-bar */
    .profile-nav-bio {
        font-size: 11px;
    }

    .profile-usertitle-name {
        font-size: 16px;
    }

    .profile-usertitle-job-bio span {
        padding: 0px 10px 2px;
    }

    .profile-sidebar-user-info p {
        padding: 10px 10px 0px;
    }

    .profile-sidebar-url-inner {
        padding: 6px 2px 2px 2px;
    }

    .profile-sidebar-user-url i {
        font-size: 13px;
        margin-top: 2px;
    }

    .profile-sidebar-url-inner .web-url {
        margin: 0px 3px 5px;
    }

    .profile-sidebar-user-contact {
        width: 100%;
        margin: 10px 0px 10px;
        display: inline-block;
    }

    .profile-sidebar-user-contact a {
        width: 120px;
        margin: 0px auto 10px;
        float: none;
        padding: 5px 10px;
    }

    .profile-sidebar-user-bottom-text span {
        width: 100%;
    }

}

@media screen and (max-width: 600px) {

    .navbar {
        padding: 4px 0;
    }

    .logo-site {
        width: 140px;
        float: left;
    }


    .navbar-dark .navbar-toggler-icon {
        background-position: center;
        background-size: cover;
        width: 20px;
        height: 20px;
    }

    .navbar-dark .navbar-toggler {
        margin: 5px 0;
    }

    .navbar-toggler {
        padding: 3px 6px 5px;
    }

    .navbar-middle {
        width: 100%;
    }

    .search-container {
        width: 250px;
        margin: 0px auto 13px;
        display: table;
        float: none;
        line-height: 20px;
        padding-top: 1px;
        padding-bottom: 2px;
    }

    .navbar-nav {
        margin: 0 auto 2px;
        width: 200px;
        float: none;
        text-align: center;
        display: table;
    }

    .top-nav>li>a {
        padding: 10px 9px 0px;
    }

    .top-nav>li span.badge {
        font-size: 11px;
        right: 0px;
        top: -5px;
        padding: 3px 2px 2px;
        width: 20px;
        height: 20px;
    }

    .top-nav>li>a>img {
        width: 31px;
        margin-top: 2px;
    }

    .navbar-expand-lg .navbar-collapse {
        width: 100%;
    }

    .navbar-middle .navbar-nav {
        font-size: 14px;
        width: 100%;
    }

    .dropdown-notification .dropdown-menu {
        min-width: 220px;
    }

    .dropdown-menu.show {
        right: -110px;
    }

    .top-nav>li.dropdown-notification .dropdown-menu>li.external {
        padding: 8px;
    }

    .top-nav>li.dropdown-notification .dropdown-menu>li.external>h3 {
        font-size: 11px;
    }

    .top-nav>li.dropdown-notification .dropdown-menu>li.external>a {
        font-size: 10px;
    }

    .top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a {
        padding: 4px 10px 11px;
        line-height: 14px;
        font-size: 11px;
    }

    .top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon {
        display: none;
    }

    #profile-cover {
        height: 130px;
        text-align: center;
        display: inline-grid;
    }

    #profile-cover .container {
        padding-top: 35px;
    }

    .cover-nav {
        margin-top: 10px;
        max-width: 100%;
        flex: 0 0 100%;

    }

    .cover-profile li {
        width: 109px;
        font-size: 9px;
    }

    .profile .sidebar-left {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 12px;
    }

    .profile .sidebar-right {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 20px;
    }

    #footer .footer-top .footer-links {
        width: 50%;
    }

    #footer .footer-top {
        padding: 20px 0 10px 0;
        margin: 0px;
    }

    .footer-bottom .copyright {
        width: 100%;
        text-align: center;
        margin: 2px 0px;
        font-size: 10px;
    }

    .profile-userpic-bio img {
        width: 40%;
        height: 40%;
    }

    .profile-sidebar-url-inner {
        padding: 6px 10px 3px 10px;
    }

    .profile-sidebar-user-contact a {
        padding: 5px 33px;
    }


    .start-learning-section h2 {
        font-size: 17px;
    }

    .start-learning-section span {
        font-size: 13px;
    }

    #footer .footer-top h4 {
        font-size: 13px;
    }

    #footer .footer-top .footer-links ul li {
        padding: 2px 0px;
        font-size: 14px;
    }

    .social-links a {
        width: 25px;
        height: 25px;
        padding: 6px 0;
        font-size: 13px;
    }

}

@media screen and (max-width: 374px) {
    .cover-profile li {
        width: 95px;
        margin-left: 0px;
    }

    .cover-profile>li>a span {
        font-size: 9px;
    }

    .cover-profile>li>a i {
        font-size: 14px;
    }

    #footer .footer-top .footer-links {
        margin-bottom: 15px;
    }

    #footer .footer-top h4 {
        font-size: 14px;
        margin-bottom: 0px;
    }

    #footer .footer-top .footer-links {
        width: 100%;
    }


}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}