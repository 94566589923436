.error-404-page {
  .error-404-page__wrapper {
    width: 100%;
    height: 100vh;
    //margin: 80px 0;
    display: flex;
    align-items: center;

    .main-content {
      &__wrapper {
        background-color: #FAFAFA;
        padding: 42px 68px;


        .page-title {
          font-style: normal;
          font-weight: bold;
          font-size: 46px;
          text-transform: uppercase;
          color: #013A6D;
          padding: 25px 0 12px 0;
        }

        h6 {
          font-style: normal;
          font-weight: bold;
          font-size: 21px;
          color: #013A6D;
          padding: 0 0 22px 0;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          text-align: center;
          color: #4F4F4F;
        }
      }
    }
  }
}