$btn_color: #013A6D;
$sidebar_backgroundColor : #828282;

$white: #fff !default;

$btn_primary_color: #013A6D;

$font-weights:(
    "regular": 400,
    "medium": 500,
    "bold": 700
);

%button {
    width: 100%;
    height: 31px;
    background-color: $btn_primary_color;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
}

%title-left {
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    color: #4F4F4F;
    margin-top: 10px;
}

%list-group {
    flex-direction: row;

    .list-group-item {
        padding: 0px;
        background-color: transparent;
        border: none;
    }
}

%list-item {
    padding: 0;
    border: 0;
}