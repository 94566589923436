.custom_tooltip {
    position:relative;
    text-align:left;
}

.custom_tooltip .custom_tooltip_content {
    min-width:200px;
    top:0;
    right: 0;
    transform:translate(0%, -100%);
    padding:10px 20px;
    color:#444444;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    transition: all 0.4s ease;
    display:none;
}

.custom_tooltip:hover .custom_tooltip_content {
    display:block;
}

.custom_tooltip .custom_tooltip_content i {
    position:absolute;
    top:100%;
    right:10%;
    margin-left:-12px;
    width:24px;
    height:12px;
    overflow:hidden;
}

.custom_tooltip .custom_tooltip_content ul {
    padding-left: 10px;
    padding-top: 5px;
}

.custom_tooltip .custom_tooltip_content i::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#EEEEEE;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
}
