@media (min-width: 300px) and (max-width: 420px) {
    .logo-site img {
        width: 140px;
        margin-bottom: 7px;
    }

    .slogan {
        font-size: 7px;
        line-height: 0;
    }

    .logo-site {
        a {
            line-height: 13px;
        }
    }

    .navbar {
        .header-nav {
            .collapse {
                ul.nav.navbar-nav.pull-right.top-nav.navbar-top {
                    margin-left: 5px;

                    .dropdown {

                        .registration-btn,
                        .login-btn {
                            font-size: 7px !important;
                        }

                        .registration-btn {
                            margin-right: 5px !important;
                        }

                        .search-top-bar {
                            margin-right: 5px !important;
                        }
                    }
                }
            }
        }
    }

    .Toastify__toast-container {
        width: auto;
        justify-content: center;
        display: flex;
    }

    .video-react .video-react-control {
        width: 2rem;
    }

    // start auth layout responsive code
    .auth-layout {
        .right-form-block {
            .registration-container {
                .registration-wrapper {
                    //text-align: center;

                    .complete-registration {
                        justify-content: center !important;
                    }

                    .input-checkbox-block {
                        text-align: left;
                    }


                    form {
                        .full-label {
                            align-items: center !important;
                        }

                        .inline-input-6 {
                            .input-block {
                                width: 40px !important;
                                height: 40px !important;
                            }
                        }

                        .action-block {
                            margin-top: 20px;
                        }
                    }

                    .link-block {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }

            .forget-password-phone-container {

                .forget-password-phone-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    form {
                        .inline-input-6 {
                            .input-block {
                                width: 40px !important;
                                height: 40px !important;
                            }
                        }
                    }
                }

            }

        }
    }

    

    // start modal responsive code
    .modal-dialog {

        .modal-content {

            .modal-header {
                padding: 16px 20px !important;

                .modal-title {
                    font-size: 17px;
                }
            }

        }
    }

    // end enroll page responsive code

    .tabs {
        //display: grid;

        li {
            margin-right: 0;
        }
    }

    .profile {
        .profile-container .notification .notofication_text {
            padding: 15px 5px;
        }
    }

    // input,
    // textarea {
    //     font-size: 12px;
    // }
}

@media (min-width: 421px) and (max-width: 767px) {
    .logo-site img {
        width: 170px;
        margin-bottom: 3px;
    }

    .slogan {
        font-size: 9px;
        line-height: 10px;
    }

    .logo-site {
        a {
            line-height: 21px;
        }
    }


}

@media only screen and (max-width: 767.98px) {
    .error-404-page {
        &__wrapper {
            margin: 30px 0 !important;
        }
    }

    

    .accordion-button .accor-duration {
        width: 94%;
        float: right;
        margin-left: 4px;
    }

    .auth-layout .left-banner-block span {
        padding-bottom: 0 !important;
    }


    // start footer section responsive code
    .footer-container {
        .footer-banner {
            padding: 30px 10px;

            h1 {
                font-size: 25px;
            }

            p {
                font-size: 12px;
                margin: 5px 25px 0 25px !important;
            }
        }

        .footer-bottom .footer-bottom-inner .footer-left,
        .footer-bottom .footer-bottom-inner .footer-right {
            text-align: center;
            width: 100%;
            display: block;
        }

        .social-links a {
            margin-right: 5px;
        }

        .footer-links {
            width: 25%;
        }

        .footer-company-description {
            text-align: center;
        }
    }

    // end footer section responsive code


    // end static page responsive code

    // start landing page responsive code
    .landing-page,
    .course-list-page {

        .common-layout-main .profile .landing-page-sub,
        .course-list-page-sub {
            margin-top: 0px;

            .category-carousal {
                .category-carousal-sub {
                    .react-multi-carousel-list {
                        .react-multiple-carousel__arrow {
                            min-width: 35px !important;
                            min-height: 35px !important;
                        }

                        //.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left,
                        //.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
                        //    display: none;
                        //}

                        .react-multi-carousel-track {
                            .react-multi-carousel-item {
                                padding: 10px;

                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            //start course large slider section responsive code
            .course-large-slider {
                .mb_p_0 {
                    padding: 0;
                }

                .sb-1 {
                    background-image: url("../assets/img/mainSider01Small.png");
                }

                .sb-2 {
                    background-image: url("./../assets/img/mainSider02Small.png");
                }

                .slider-banner {
                    background-size: 100%;
                    //height: calc(100vh - 150px);
                    height: 535px;
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    background-position: center bottom -30px;
                    margin-left: 0;

                    &>.row {
                        margin-top: auto;
                    }

                    .sb-left {
                        margin-bottom: 25px;

                        h1 {
                            color: #ffffff;
                            font-size: 26px !important;
                            margin-bottom: 20px;
                        }

                        p {
                            display: none;
                        }

                        .action-block button.form-submit {
                            width: 92%;
                        }
                    }
                }

                .react-multi-carousel-list {

                    .react-multiple-carousel__arrow--left
                    {
                    left: calc(4% + 20px);
                    }
                }

                .react-multiple-carousel__arrow--right {
                    right: calc(4% + 20px);
                }
            }
        }

        //start course large slider section responsive code

        //start why coders trust section responsive code
        .why-ct-section {
            margin: 0 !important;
            width: 100%;

            .why-ct {
                .why-ct-title {
                    font-size: 25px;
                }

                .why-ct-content {
                    flex-direction: column;
                    //padding: 0 50px;

                    .cnt {
                        width: 100%;
                        margin: 40px 0 0 0;
                    }

                    .content-1 {
                        margin-right: 0;
                    }

                    .content-1,
                    .content-2,
                    .content-3 {
                        border: 1px solid #f2f2f2;
                        background-image: none;
                    }

                    .content-2,
                    .content-3 {
                        //margin: 0;
                    }

                    .content-3 {
                        //margin-top: 60px;
                    }
                }
            }
        }

        //end why coders trust section responsive code

        //start best selling section responsive code
        .best-selling {
            width: 100%;
            margin: 40px 0;
            //padding: 0 50px;

            .best-selling-title {
                font-size: 25px !important;
            }

            .para-course {
                //font-size: 18px !important;
            }

            //.best-selling-carousal{
            //    margin: 0 20px;
            //}
        }

        //start best selling section responsive code


        //start live online courses section responsive code
        .live-online-courses-section {

            .live-online-courses {

                .loc-bg-5 {
                    height: 50%;
                }
            }
        }


        //end live online courses section responsive code
        .live-online-courses-section {

            .live-online-courses-carousal {
                //padding: 0 70px;
            }
        }

        .live-online-courses-carousal {
            .course-card-slider {
                .rec-arrow-left {
                    //left: 12px;
                }

                .rec-arrow-right {
                    //right: 12px;
                }
            }
        }

        .featured {
            .featured-title {
                font-size: 35px;
            }

            .featured-carousal {
                //margin: 0 70px;
                padding: 0 !important;

                .course-card-slider {}
            }
        }

        //start become an instructor section responsive code
        .become-instructor-main-section {
            margin: 0 !important;

            .become-instructor-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                //padding: 0 50px;

                .bis-left {
                    width: 100%;
                    text-align: center;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .bis-right {
                    width: 100%;
                    margin-top: 15px;

                    h5 {
                        //font-size: 25px;
                        //letter-spacing: 2px;
                        text-align: center;
                    }

                    .para-1 {
                        margin-top: 15px !important;
                    }

                    .para-1,
                    .para-2 {
                        font-size: 18px;
                        text-align: center;
                    }

                    .action-block {
                        justify-content: center;
                    }
                }
            }
        }

        //end become an instructor section responsive code

        .global-revenue-courses {
            margin: 0 50px;
            padding-top: 20px;

            h5 {
                font-size: 30px;
            }

            .para-1 {
                margin-bottom: 10px !important;
            }

            .grc-main {
                margin-top: 40px;
            }
        }

        // start Our Focus On You responsive code
        .focus-on-u-section {
            margin-bottom: 0 !important;

            .focus-on-u-section-sub {
                padding: 0 !important;

                //margin: 0 50px;
                h5 {
                    font-size: 25px;
                }

                .para-1 {
                    text-align: center;
                    font-size: 18px;
                }

                .fus-content {
                    flex-direction: column;

                    .fus-cnt {
                        width: 100%;
                        margin: 20px 0 !important;

                        //margin: 30px auto;
                        p {
                            text-align: justify;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        // end Our Focus On You responsive code

        // start we-are-growing-fast responsive code
        .we-are-growing-fast {
            margin: 30px 0 !important;
            //padding: 0 50px;

            .student-growing-section {
                flex-direction: column;
                padding: 20px 20px;

                .sgs-left {
                    padding: 0 10px;
                    margin-left: 0;
                    text-align: center;

                    h1 {
                        font-size: 35px;
                        text-align: center;
                    }
                }

                .sgs-right {
                    //width: 100%;
                    margin-top: 15px;
                    margin-right: 0;
                    text-align: center;

                    .box1,
                    .box2,
                    .box3 {
                        h5 {
                            font-size: 30px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }

                    .box2 {
                        margin: 15px 0;
                    }
                }
            }
        }



        // end subscribe newsletter section responsive code

        // start success story section responsive code
        .success-story-section {
            .success-section {
                flex-direction: column;
                margin: 50px 0px 220px;
                //margin: 35px 0px;

                .success-section-left {
                    width: 100%;
                    margin: 0;

                    .success-story-text {
                        margin-bottom: 30px;

                        h1 {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 30px;
                            line-height: 36px;
                            text-align: center;
                            color: #013A6D;
                            padding-top: 30px;

                            b {
                                font-weight: bold;
                                font-size: 30px;
                            }
                        }
                    }

                    .text-slider {
                        //margin: 0 20px;

                        .text-slider-wrapper {
                            width: 100%;
                            top: 62vw;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;

                            #carouselContent {
                                .carousel-inner {
                                    padding: 10px 15px;

                                    .carousel-item {
                                        .quote-image {
                                            width: 20px;
                                        }

                                        .review-text {
                                            font-size: 15px;
                                        }

                                        .user-name {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .success-section-right {
                    width: 100%;

                    .next-prev-control {
                        .carousel-control-prev {
                            right: 0;
                            left: -120px;
                            top: 6px;
                        }

                        .carousel-control-next {
                            right: 30px;
                            top: 6px;
                        }

                        a.carousel-control-prev,
                        a.carousel-control-next {
                            width: 40px;
                            height: 40px;

                            img {
                                width: 10px;
                            }
                        }
                    }

                    .success-story-image {
                        //margin: 0 20px;

                        .image {
                            clip-path: none;
                            border-radius: 5px;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }
        }

        // end success story section responsive code

        // start subscribe section responsive code
        .subscribe-section {
            margin-bottom: -1px;

            .subscribe-section-sub {
                flex-direction: column;
                padding: 30px 0;

                .subscribe-section-left-side {
                    width: 100%;

                    h1 {
                        font-size: 30px;

                        br {
                            display: none;
                        }
                    }
                }

                .subscribe-section-right-side {
                    width: 100%;

                    .input-checkbox-block {
                        span.name {
                            font-size: 10px;
                        }

                    }

                    .input-block {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        // end subscribe section responsive code
    }
}

// end landing page responsive code

// start course list page responsive code
.course-list-page {
    .course-list-page-sub {
        .course-list-page-wrapper {
            .banner-section {
                .hero-section {
                    .hero-section-left-side {
                        margin: 0 auto;
                        //margin: 30px 10px 10px;
                        text-align: center;

                        &__title {
                            font-size: 30px;
                            margin-top: 30px;

                            .course-list-best-selling {
                                font-size: 30px !important;
                            }
                        }

                        &__description {
                            margin-bottom: 10px !important;
                        }
                    }

                    .hero-section-right-side {
                        justify-content: center;

                        &__image-section {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }

            .main-section-course-list-page {
                .title {
                    text-align: center;
                    font-size: 18px !important;
                }

                .tabs {
                    .current {
                        text-align: center;
                    }
                }

                .my-courselist-content {
                    margin: 0 10px;
                }
            }

            .course-filtering-section {
                .main-filtering-section {
                    .notification {
                        .warning-text {
                            font-size: 15px !important;
                        }
                    }

                    .course-filter {
                        .course-filter-right-side {
                            margin-top: 25px;

                            .all-course-show {
                                .course-card {
                                    display: flex;
                                    justify-content: center;

                                    //margin: 0 15px 20px 0;
                                    //width: 290px;
                                    .card {
                                        width: 238px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .become-instructor-section {
                .become-instructor-section-wrapper {
                    margin-top: 100px !important;

                    .become-instructor-left-side {
                        text-align: center;
                    }

                    .become-instructor-right-side {
                        margin: 20px 0;
                        text-align: center;

                        .become-instructor-title {
                            font-size: 30px !important;
                        }

                        .become-instructor-text {
                            font-size: 15px !important;
                        }

                        .action-block {
                            justify-content: center !important;
                        }
                    }
                }
            }
        }
    }
}

// end course list page responsive code

.course-list-page {
    margin-top: 0;

    .section-main-contact-us .section-container .paragraph-section .spacing-hh-40 {
        margin-top: 20px;
    }

    .career-main .career-container {
        margin: 0 20px;

        .career-header {
            margin-top: 20px !important;
        }
    }

    .section-main {
        .section-container {
            margin: 0 20px;

            .section-header {
                margin-top: 20px !important;
                margin-bottom: 0 !important;
            }

            .paragraph-section {
                .paragraph-header {
                    margin-bottom: 10px;
                    margin-top: 30px;
                }
            }
        }
    }

    .section-container {
        margin: 0 20px;
    }

    .section-main-privacy {
        margin-top: 20px;
    }
}

// start static page responsive code
.static-page-section {
    margin: 0 !important;

    .header-header-main {
        //margin-top: 0;
        //padding-top: 0;
        height: 70px;

        .header-content {
            //background-color: transparent;
            //color: #243c6e;

            h3 {
                font-size: 25px;
                padding: 10px;
            }
        }
    }

    .section-main-about-us {
        .section-container {
            margin: 0 20px !important;

            .paragraph-section {
                .spacing-hh-50 {
                    margin-top: 20px;
                }

                .paragraph-body {
                    text-align: justify;

                }
            }
        }
    }

    .section-main {
        .section-container {
            .section-header {
                h1 {
                    font-size: 25px;
                }
            }
        }
    }

    .section-main-contact-us {
        .section-container {
            margin: 0 20px;

            .paragraph-section {
                .paragraph-body {
                    h5 {
                        font-size: 18px;
                    }
                }
            }

            .contact-us-body {
                padding: 0;

                .contact-us-form {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

// end static page responsive code

// start my cart page responsive code
.my-cart-page {
    .cart-banner {
        display: none;
    }

    .cart-layout-sub {
        margin-top: 0;

        .my-cart-top-section {
            flex-direction: column;
            margin: 0;

            .my-cart-top-section-left,
            .my-cart-top-section-right {
                width: 100%;
                margin-top: 0;
            }

            .my-cart-top-section-left {
                align-items: center;

                .my_cart_title {
                    color: #243c6e;
                    margin-bottom: 0;
                    padding: 10px;
                    text-align: center;
                    font-size: 25px;
                }

                .my-cart-list {
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;

                    .cart-block {
                        flex-direction: column;
                        width: 300px;

                        .cart-block-left {
                            margin-right: 0;

                            .my_cart_static_img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        .cart-block-right {
                            .sm-course-detail {
                                padding-bottom: 10px;
                            }

                            .top-el {
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }

        }
    }

    .footer-container {
        margin-top: 10px;
    }
}

// end my cart page responsive code

// start purchase history page responsive code
.purchase-history-page {
    .purchase-order-list {
        .single-item {
            .body-section {
                flex-wrap: wrap;
                margin-left: 0;
            }

            .body-section>div {
                margin: 10px 10px 10px 0;
            }
        }
    }
}

// end purchase history page responsive code

// start order details page responsive code
.order-details-page {

    .pdf-page .pdf-section-3 .table_1 tr th:last-child,
    .pdf-page .pdf-section-3 .table_1 tr td:last-child,
    .pdf-page .pdf-section-3 .table_2 tr td:last-child,
    .pdf-page .pdf-section-3 .table_1 tr th:nth-child(2),
    .pdf-page .pdf-section-3 .table_1 tr td:nth-child(2),
    .pdf-page .pdf-section-3 .table_2 tr td:nth-child(2) {
        text-align: right;
        padding-right: 0;
    }

    .pdf-page .pdf-section-3 .table_1 th:last-child,
    .pdf-page .pdf-section-3 .table_1 td:last-child,
    .pdf-page .pdf-section-3 .table_2 td:last-child {
        width: 25%;
    }

    .pdf-page .pdf-section-3 .table_1 tr th:nth-child(2),
    .pdf-page .pdf-section-3 .table_2 td:first-child {
        width: 0%;
    }

    .pdf-page .pdf-section-3 .table_2 td:nth-child(2) {
        width: 50%;
    }

    .common-layout-main {
        .profile {
            .order-details-sub {
                margin-top: 0;

                .order-banner-section {
                    .order-banner-sub {
                        flex-direction: column;
                        align-items: flex-start;
                        word-break: break-word;
                        padding: 30px 15px;

                        .banner-left h1 {
                            font-size: 30px;
                        }

                        .banner-right {
                            width: 100%;
                            text-align: center;
                            margin-top: 20px;
                        }
                    }
                }

                .pdf-page {

                    .pdf-section-1 {
                        padding: 20px 0;
                        flex-wrap: wrap;
                        text-align: left;

                        .pdf-section-1-left {
                            width: 30%;

                            img {
                                min-width: 120px;
                                width: 100%;
                                margin-bottom: 20px;
                            }
                        }

                        .pdf-section-1-right {
                            text-align: left;
                        }
                    }

                    .pdf-section-2 {
                        margin-bottom: 15px;

                        .pdf-section-2-left {
                            margin-right: 20px;
                        }
                    }
                }

                .e-g-note {
                    font-size: 14px;
                }
            }
        }
    }
}

// end order details page responsive code

// start profile cover section responsive code
#profile-cover {
    height: 120px;
}

// end profile cover section responsive code

// start sidebar left section responsive code
.sidebar-left {
    .profile-sidebar {
        .profile-userpic {
            .profile-userpic-inner {
                img {
                    width: 90%;
                    height: 90%;
                }

                .edit-circle-white {
                    right: 6%;
                }
            }
        }
    }

    .profile-sidebar-bio {
        .profile-userpic-bio {

            img {
                width: 135px;
                height: 135px;
            }
        }
    }
}

// end sidebar left section responsive code

// start course create/update page responsive code
.course_details_container {
    //margin-top: 0;

    .course_details_first_page,
    .course_details_second_page {
        width: 90%;
        padding: 0 15px !important;
    }

    .course_details_third_page {
        width: 100%;
        padding: 0 15px;

        .course_overview_thumb_img {
            margin-top: 0;
        }

        .all-tab {
            margin-top: 0;

            .tab-list {
                .nav {
                    white-space: nowrap;
                    overflow-x: auto;
                    flex-wrap: nowrap;
                    //margin: 0 !important;
                }

                /* Hide scrollbar for Chrome, Safari and Opera */
                .nav::-webkit-scrollbar {
                    display: none;
                }

                /* Hide scrollbar for IE, Edge and Firefox */
                .nav {
                    -ms-overflow-style: none;
                    /* IE and Edge */
                    scrollbar-width: none;
                    /* Firefox */
                }

                .change-promo-button {
                    padding: 2px 16px 3px;
                    font-size: 13px;
                }
            }
        }
    }
}

// start course create/update responsive code

// start modal responsive code
.modal-dialog {

    .modal-content {

        .modal-header {

            .modal-title {}
        }

        .modal-body {
            .content_positioned {
                left: 10%;
                right: 10%;
            }

            .final_setup {
                flex-direction: column;
            }

            .lesson_add {
                .lesson_add_sub {
                    width: 100%;

                    .player .uploading-progress {
                        top: 10%;
                        left: 35%;
                    }
                }
            }

            .chapter-add {
                .chapter-add-sub form {
                    width: 100%;
                    margin-top: 20px;
                }
            }

            .is-public {
                font-size: 14px;
            }

            .loadingio-spinner-spinner-q07824z04ra {
                width: 90px;
                height: 90px;

                .ldio-ilnxocv4tsn div {
                    left: 40px;
                    width: 8px;
                    height: 22px;
                    border-radius: 6px/12px;
                    transform-origin: 3px 35px;
                }
            }
        }
    }
}

// end modal responsive code

// start enroll page responsive code
.tutorial-details-page__wrapper {
    .tab-section {
        .tab-list {
            .nav {
                white-space: nowrap;
                overflow-x: auto;
                flex-wrap: nowrap;
            }

            /* Hide scrollbar for Chrome, Safari and Opera */
            .nav::-webkit-scrollbar {
                display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .nav {
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */
            }
        }

        .about-section .about-head-section {
            padding-top: 0;
        }
    }
}


/// Medium devices (landscape tablets, 768px and up) /
@media (min-width: 768px) and (max-width: 991.98px) {
    .auth-layout {
        flex-direction: column;
        //justify-content: space-between;

        .left-banner-block {
            background-color: #243c6e;
            width: 100% !important;
            text-align: center;
            padding: 40px 0;
            height: 35vh;
            z-index: 1;
            position: relative;

            .Logo-position {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                .ct-text {
                    width: 245px;
                }

                .auth-slogan {
                    font-size: 12px;
                }
            }

            .skill-banner {
                display: none;
            }
        }

        .right-form-block {
            margin: 0 auto 0 !important;
            width: 100%;
            z-index: 2;
            justify-content: center;

            .login-container {
                width: 100%;
                display: flex;
                justify-content: center !important;
                height: auto;

                .login-wrapper {

                    h2 {
                        justify-content: center;
                    }

                    .btn-block {
                        flex-direction: column;
                        align-items: center;

                        .btn-facebook {
                            width: 100%;
                            margin: 10px 0;
                        }

                        .btn-linkedin {
                            width: 200px;
                        }

                        .btn-gmail,
                        .btn-linkedin {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    .action-block {
                        flex-direction: column;

                        .form-submit {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .registration-container {
                width: 100% !important;
                height: auto;

                .registration-wrapper {
                    padding-top: 30px;
                    padding-bottom: 30px;

                    .confirm,
                    .confirm-last {
                        text-align: center;
                    }

                    .inline-input {
                        align-items: flex-start;
                        flex-direction: column;

                        .input-block {
                            width: 100% !important;
                        }
                    }

                    .link-block {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;
                    }

                    form {
                        .full-label {
                            flex-direction: column;
                            align-items: center;
                        }

                        .inline-input-6 {
                            justify-content: center;
                            flex-wrap: wrap;


                            .input-block {
                                //width: 66.6px;
                                width: 40px !important;
                                height: 40px !important;
                            }
                        }

                        .action-block {
                            margin-top: 20px;
                        }
                    }

                    h2 {
                        justify-content: center;
                    }

                    .complete-registration {
                        justify-content: center;
                    }

                    .btn-block {
                        flex-direction: column;
                        align-items: center;

                        .btn-facebook {
                            width: 100%;
                            margin: 10px 0;
                        }

                        .btn-linkedin {
                            width: 200px;
                        }

                        .btn-gmail,
                        .btn-linkedin {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    .input-checkbox-block {
                        .custom-checkbox-control-label {
                            margin-bottom: 0;
                        }

                        span.name {
                            font-size: 10px;
                        }
                    }

                    .action-block {
                        flex-direction: column;
                        align-items: flex-start;

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }

                    .contact-otp {
                        align-items: center;
                    }
                }

                .login-link {
                    padding: 10px 15px !important;
                    //padding: 20px 45px !important;
                    text-align: left;
                    margin: 0;
                }
            }

            .forget-password-container {
                width: 100% !important;
                justify-content: flex-start;
                max-height: 100%;
                //max-height: calc(100vh - 121px);

                .forget-password-wrapper {

                    p {
                        margin-bottom: 10px !important;
                    }

                    h2 {
                        justify-content: center;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        .action-block {
                            align-items: center;
                            flex-direction: column;
                        }

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }
                }
            }

            .password-reset-container {
                width: 100%;
                height: 100%;

                .password-reset-wrapper {

                    form {
                        .action-block {
                            align-items: center;
                            flex-direction: column;
                        }

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }
                }
            }

            .forget-password-phone-container {
                width: 100%;
                flex-direction: row;
                height: auto;

                .forget-password-phone-wrapper {
                    width: 100%;
                    padding: 20px 15px;

                    //height: calc(100vh - 345px);
                    h2 {
                        justify-content: center;
                        margin-bottom: 10px !important;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        .full-label {
                            flex-direction: column;
                            align-items: center;

                            .aaaa {
                                .forgot-timer {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .inline-input-6 {
                            justify-content: center;
                            flex-wrap: wrap;

                            .input-block {
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .action-block {
                            flex-direction: column;
                            align-items: center;

                            .form-submit {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }


    .slogan {
        font-size: 12px;
    }

    div#navbarCollapse {
        height: 50px;
        align-items: center;
    }

    .navbar-middle .navbar-nav {
        margin-top: 0;
    }

    .search-container {
        margin-top: 0;
    }

    .navbar-top {
        margin-top: 0;
    }



    .navbar {
        .top-nav {
            padding: 0;
        }

        .navbar-expand-lg .navbar-collapse {
            align-items: baseline;
        }
    }

    // start static page responsive code
    .static-page-section {
        margin: 0 !important;

        .header-header-main {

            //padding-top: 60px;
            //padding-top: calc(50vh - 405px);
            .header-content {
                height: 80px;
                margin: 0 40px;
            }
        }

        .section-main {
            .section-container {
                .section-header {
                    h1 {
                        padding-top: 25px;
                        font-size: 30px;
                    }
                }
            }
        }

        .section-main-contact-us {
            .section-container {
                padding: 0 40px;

                .contact-us-body {
                    margin-top: 40px;
                }
            }
        }

        .section-main-about-us,
        .section-main-faq {
            .section-container {
                margin-top: 44px;
            }
        }
    }

}

@media only screen and (max-width: 991.98px) {
    ul.nav.navbar-nav.pull-right.top-nav.navbar-top {
        .dropdown.search-top-bar.show {
            .dropdown-menu.login-navbar.show {
                .search-container {
                    input.header-search {
                        position: relative;
                        padding: 4px 25px 6px 35px !important;
                    }
                }
            }
        }
    }
    .navbar {
        position: relative;
        width: 100%;
        .header-nav {
            .navbar-middle {
                display: table;

                .navbar-nav {
                    margin-bottom: 10px;

                    li {
                        margin-right: 0;
                    }
                }
            }
            .search-container {
                margin-bottom: 0;
                width: 100%;
            }

            .navbar-nav {
                display: flex;
                justify-content: center;
                flex-direction: row;
                width: 100%;
            }
        }
    }
    .my-course-page,
    .public-profile-container,
    .home-page,
    .public-profile-instructor,
    .my-course-page,
    .profile-page,
    .purchase-history-page,
    .notification-settings-page,
    .search-page {
        .footer-sticky {
            display: block !important;
        }
    }
    .common-layout-main {
        .footer-sticky {
            display: block !important;
        }
    }
}

@media only screen and (max-width: 991.98px) and (orientation: landscape) {
    .login-page,
    .registration-page,
    .forget-password-page,
    .forget-password-phone-page,
    .password-reset-page {
        .auth-layout {
            height: 100%;
            .left-banner-block {
                padding: 40px;
                height: auto !important;
            }
            .right-form-block {
                .login-container,
                .registration-container,
                .forget-password-phone-container {
                    width: 100%;
                    height: 100%;
                }
                .forget-password-container {
                    max-height: 100%;

                    .forget-password-wrapper {
                        padding: 15px;
                    }
                }
                .forget-password-phone-container {
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
                .password-reset-container {
                    flex-direction: row;
                    .password-reset-wrapper {
                        padding: 20px;
                    }
                }
            }
        }
    }
    .forget-password-phone-page {
        .auth-layout {
            height: 100vh;
        }
    }
    .accordion-scrollable {
        max-height: 100% !important;
    }
}


//Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {
    .auth-registration-btn {
        margin-left: 12px;
    }
    .pagination-component {
        .pagination-component-wrapper {
            margin-bottom: 21px;
        }
    }

    .static-page-section {
        .section-main-about-us {

            .section-container,
            .section-main-faq {
                margin-top: 0;
            }
        }

        .section-main {
            .section-container {
                .section-header {
                    h1 {
                        font-size: 35px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .search-container .header-search {
        width: 160px;
    }
    .logo-site img {
        width: 210px;
    }
    .slogan {
        font-size: 11px;
    }
    div#navbarCollapse {
        height: 47px;
        align-items: center;
    }
    .navbar-middle .navbar-nav {
        margin-top: 0;
    }
    .search-container {
        margin-top: 0;
    }
    .navbar-top {
        margin-top: 0;
    }
}

/// Extra large devices (large laptops and desktops, 1200px and up) /
@media only screen and (min-width: 1200px) {

    .search-container .header-search {
        width: auto;
    }
    div#navbarCollapse {
        height: 50px;
        align-items: center;
    }
    .navbar-middle .navbar-nav {
        margin-top: 0;
    }
    .search-container {
        margin-top: 0;
    }
    .navbar-top {
        margin-top: 0;
    }
    .logo-site img {
        width: 160px;
    }
}


// Low priority media query ( landing page slider media query)

@media (min-width: 768px) and (max-width: 991.98px) {
    .mobile-margin {
        margin-top: 10px;
    }
}


@media (min-width: 1450px) {
    .container {
        max-width: 1300px;
    }
}

@media (min-width: 1537px) {
    .container {
        max-width: 1450px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .auth-layout {
        flex-direction: column;;

        .left-banner-block {
            background-color: #243c6e;
            width: 100% !important;
            text-align: center;
            padding: 40px 0;
            z-index: 1;
            position: relative;
            height: 40vh;

            .Logo-position {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                .ct-text {
                    width: 245px;
                }

                .auth-slogan {
                    font-size: 12px;
                }
            }

            .skill-banner {
                display: none;
            }
        }

        .right-form-block {
            margin: 0px auto 0 !important;
            width: 100%;
            z-index: 2;
            justify-content: center;
            .login-container {
                width: 100%;
                display: flex;
                justify-content: flex-start !important;
                height: auto;

                .login-wrapper {

                    h2 {
                        justify-content: center;
                    }

                    .btn-block {
                        flex-direction: column;
                        align-items: center;

                        .btn-facebook {
                            width: 100%;
                            margin: 10px 0;
                        }

                        .btn-linkedin {
                            width: 200px;
                        }

                        .btn-gmail,
                        .btn-linkedin {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    .action-block {
                        flex-direction: column;

                        .form-submit {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .registration-container {
                width: 100% !important;
                height: auto;

                .registration-wrapper {

                    .confirm,
                    .confirm-last {
                        text-align: center;
                    }

                    .inline-input {
                        align-items: flex-start;
                        flex-direction: column;

                        .input-block {
                            width: 100% !important;
                        }
                    }

                    .link-block {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;
                    }

                    form {
                        .full-label {
                            flex-direction: column;
                            align-items: center;
                        }

                        .inline-input-6 {
                            justify-content: center;
                            flex-wrap: wrap;


                            .input-block {
                                //width: 66.6px;
                                width: 40px !important;
                                height: 40px !important;
                            }
                        }

                        .action-block {
                            margin-top: 20px;
                        }
                    }

                    h2 {
                        justify-content: center;
                    }

                    .complete-registration {
                        justify-content: center;
                    }

                    .btn-block {
                        flex-direction: column;
                        align-items: center;

                        .btn-facebook {
                            width: 100%;
                            margin: 10px 0;
                        }

                        .btn-linkedin {
                            width: 200px;
                        }

                        .btn-gmail,
                        .btn-linkedin {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    .input-checkbox-block {
                        .custom-checkbox-control-label {
                            margin-bottom: 0;
                        }

                        span.name {
                            font-size: 10px;
                        }
                    }

                    .action-block {
                        flex-direction: column;
                        align-items: flex-start;

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }

                    .contact-otp {
                        align-items: center;
                    }
                }

                .login-link {
                    padding: 10px 15px !important;
                    //padding: 20px 45px !important;
                    text-align: left;
                    margin: 0;
                }
            }

            .forget-password-container {
                width: 100% !important;
                justify-content: flex-start;
                max-height: 100%;
                //max-height: calc(100vh - 121px);

                .forget-password-wrapper {

                    p {
                        margin-bottom: 10px !important;
                    }

                    h2 {
                        justify-content: center;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        .action-block {
                            align-items: center;
                            flex-direction: column;
                        }

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }
                }
            }

            .password-reset-container {
                width: 100%;
                height: 100%;

                .password-reset-wrapper {

                    form {
                        .action-block {
                            align-items: center;
                            flex-direction: column;
                        }

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }
                }
            }

            .forget-password-phone-container {
                width: 100%;
                flex-direction: row;
                height: auto;

                .forget-password-phone-wrapper {
                    width: 100%;
                    padding: 20px 15px;

                    //height: calc(100vh - 345px);
                    h2 {
                        justify-content: center;
                        margin-bottom: 10px !important;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        .full-label {
                            flex-direction: column;
                            align-items: center;

                            .aaaa {
                                .forgot-timer {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .inline-input-6 {
                            justify-content: center;
                            flex-wrap: wrap;

                            .input-block {
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .action-block {
                            flex-direction: column;
                            align-items: center;

                            .form-submit {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .edit-hover.edit-button-background {
        float: right;
    }

    .mobile-margin {
        margin-top: 10px;
    }
    .error-404-page {
        &__wrapper {
            .main-content {
                &__wrapper {
                    padding: 35px 20px !important;

                    img {
                        width: 80px !important;
                    }

                    .page-title {
                        font-size: 35px !important;
                    }

                    h6 {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }

}

@media (max-width: 575.98px) {
    .auth-layout {
        //justify-content: space-between;

        .left-banner-block {
            background-color: #243c6e;
            width: 100% !important;
            height: 40vh;
            text-align: center;
            padding: 40px 0;
            z-index: 1;
            position: relative;

            .Logo-position {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                .ct-text {
                    width: 245px;
                }

                .auth-slogan {
                    font-size: 12px;
                }
            }

            .skill-banner {
                display: none;
            }
        }

        .right-form-block {
            .login-container {
                width: 100%;
                display: flex;
                justify-content: flex-start !important;
                height: auto;

                .login-wrapper {
                    box-sizing: border-box;
                    max-width: 550px;
                    padding: 36px 30px 30px;
                    width: 100%;

                    h2 {
                        justify-content: center;
                        font-size: 15px;
                    }

                    .btn-block {
                        flex-direction: column;
                        align-items: center;

                        .btn-facebook {
                            width: 100%;
                            margin: 10px 0;
                        }

                        .btn-linkedin {
                            width: 200px;
                        }

                        .btn-gmail,
                        .btn-linkedin {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    .action-block {
                        flex-direction: column;

                        .form-submit {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .registration-container {
                width: 100% !important;
                height: auto;

                .registration-wrapper {

                    .confirm,
                    .confirm-last {
                        text-align: center;
                    }

                    .inline-input {
                        align-items: flex-start;
                        flex-direction: column;

                        .input-block {
                            width: 100% !important;
                        }
                    }

                    .link-block {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;
                    }

                    form {
                        .full-label {
                            flex-direction: column;
                            align-items: center;
                        }

                        .inline-input-6 {
                            justify-content: center;
                            flex-wrap: wrap;


                            .input-block {
                                //width: 66.6px;
                                width: 40px !important;
                                height: 40px !important;
                            }
                        }

                        .action-block {
                            margin-top: 20px;
                        }
                    }

                    h2 {
                        justify-content: center;
                    }

                    .complete-registration {
                        justify-content: center;
                    }

                    .btn-block {
                        flex-direction: column;
                        align-items: center;

                        .btn-facebook {
                            width: 100%;
                            margin: 10px 0;
                        }

                        .btn-linkedin {
                            width: 200px;
                        }

                        .btn-gmail,
                        .btn-linkedin {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    .input-checkbox-block {
                        .custom-checkbox-control-label {
                            margin-bottom: 0;
                        }

                        span.name {
                            font-size: 10px;
                        }
                    }

                    .action-block {
                        flex-direction: column;
                        align-items: flex-start;

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }

                    .contact-otp {
                        align-items: center;
                    }
                }

                .login-link {
                    padding: 10px 15px !important;
                    //padding: 20px 45px !important;
                    text-align: left;
                    margin: 0;
                }
            }

            .forget-password-container {
                width: 100% !important;
                justify-content: flex-start;
                max-height: 100%;
                //max-height: calc(100vh - 121px);

                .forget-password-wrapper {
                    width: 100% !important;
                    padding: 15px;

                    p {
                        margin-bottom: 10px !important;
                    }

                    h2 {
                        justify-content: center;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        .action-block {
                            align-items: center;
                            flex-direction: column;
                        }

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }
                }
            }

            .password-reset-container {
                width: 100%;
                height: 100%;

                .password-reset-wrapper {

                    form {
                        .action-block {
                            align-items: center;
                            flex-direction: column;
                        }

                        .form-submit {
                            width: 100%;
                            margin: 10px 0;
                        }
                    }
                }
            }

            .forget-password-phone-container {
                width: 100%;
                flex-direction: row;
                height: auto;

                .forget-password-phone-wrapper {
                    width: 100%;
                    padding: 20px 15px;

                    //height: calc(100vh - 345px);
                    h2 {
                        justify-content: center;
                        margin-bottom: 10px !important;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        .full-label {
                            flex-direction: column;
                            align-items: center;

                            .aaaa {
                                .forgot-timer {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .inline-input-6 {
                            justify-content: center;
                            flex-wrap: wrap;

                            .input-block {
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .action-block {
                            flex-direction: column;
                            align-items: center;

                            .form-submit {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }


    .mobile-margin {
        margin-top: 10px;
    }
    .accordion-button .edit-icon {
        justify-content: center;
    }

    .error-404-page {
        &__wrapper {
            .main-content {
                &__wrapper {
                    padding: 35px 20px !important;

                    img {
                        width: 60px !important;
                    }

                    .page-title {
                        font-size: 30px !important;
                    }

                    h6 {
                        font-size: 15px !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-height: 850px) {
    .registration-page {
        .auth-layout {
            height: 100vh;

            .right-form-block {
                .registration-container {
                    width: calc(100vw - 475px);
                    height: 100%;
                }
            }
        }
    }
}