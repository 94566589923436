@import "../../../scss/variables";

.navbar-nav a,
.navbar-nav a:hover {
  color: #FFFFFF;
}

.navbar-nav a.current {
  font-weight: bold;
}

.navbar {
  .header-nav {
    .logo-site {
      width: auto;

      img {
        max-width: 260px;
        min-width: 220px
      }

      span {
        svg {
          path {
            fill: #f57c00;
          }
        }
      }
    }
  }
}

.logo-bid-temp {
  color: #f57c00;
  text-align: right;
  font-family: 'Poppins';
  font-weight: bolder;
}

.slogan {
  display: block;
  font-size: 12px;
  color: #f57c00;
  text-align: right;
  font-family: 'Poppins';
}

.registration-btn {
  padding: 7px 10px !important;
  margin-right: 8px;
  background-color: #F2F2F2 !important;
  //font-weight: 600 !important;
  color: #243C6E !important;

  &:hover {
    color: #243C6E !important;
  }

}

.login-btn {
  background-color: #F57C00 !important;
  color: #FFFFFF;
  padding: 7px 12px !important;

  &:hover {
    color: #FFFFFF !important;
  }
}

.registration-btn,
.login-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  border: none;

  &:hover {
    color: #243C6E;
  }

}


//#navbarCollapse{
//  padding: 4px 0;
//}

.search-container {
  .close-icon1 {
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;

    img {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .header-search:not(:valid)~.close-icon1 {
    display: none;
  }
}

ul.nav.navbar-nav.pull-right.top-nav.navbar-top {
  .dropdown.search-top-bar.show {
    .dropdown-menu.login-navbar.show {
      .search-container.navbar-top {
        input.header-search {
          position: relative;
        }

        .close-icon1 {
          position: absolute;
          right: 6px;
          padding: 4px 0 6px 0;
        }

        .header-search:not(:valid)~.close-icon1 {
          display: none;
        }
      }
    }
  }
}

.search-box-login {
  .search-input-field {
    .search-container.navbar-top {
      input.header-search {
        position: relative;
      }

      .close-icon1 {
        position: absolute;
        right: 6px;
        padding: 4px 0 6px 0;
      }

      .header-search:not(:valid)~.close-icon1 {
        display: none;
      }
    }
  }
}

.bid-mobile-menu-sidebar {
  display: none;
}

.header-nav.main-header-area {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .countdown-wrapper.header-countdown {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;

    .coming-soon-text-l h3 {
      color: #fff;
      font-size: 31px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-right: 20px;
      text-transform: capitalize;
    }

    .time-section {
      margin: 0 10px;

      .time {
        color: #fff;
        font-size: 32px;
        line-height: 1;
        margin-bottom: 5px;
        font-weight: 900;
      }

      small.time-text {
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}

ul.dropdown-menu.profile-sub {
  border-radius: 0px;
  margin-top: 34px;
  padding: 0;
  box-shadow: 0px 10px 26px 0px rgb(50 50 50 / 19%);
  transition: all .3s ease;
  opacity: 0;
  visibility: hidden;

  li {
    padding: 0;
    border: none;

    &.divider {
      display: none;
    }

    &:last-child a {
      border: none;
    }

    a {
      padding: 8px 12px;
      border-bottom: 1px solid #ddd;
      transition: all .3s ease;

      i {
        margin-right: 3px;
      }

      &:hover {
        color: #fff;
        background: var(--primary-color);
      }
    }
  }

  &:after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    width: 28px;
    height: 16px;
    background: #fff;
    top: -15px;
    right: 5px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.show {
    opacity: 1;
    visibility: visible;
    margin-top: 24px;
  }
}


@media (max-width:1100px) {
  .header-nav.main-header-area {
    .countdown-wrapper.header-countdown {
      .coming-soon-text-l h3 {
        font-size: 22px;
        margin-right: 10px;
      }

      .time-section {
        margin: 0 4px;

        .time {
          font-size: 28px;
        }
      }
    }
  }
}

@media (max-width:991px) {
  .header-nav.main-header-area {
    .countdown-wrapper.header-countdown {
      .coming-soon-text-l h3 {
        font-size: 24px;
      }

      .time-section {
        .time {
          font-size: 28px;
        }

        small.time-text {
          font-size: 14px;
        }
      }
    }
  }

  .mobile-nav-icon {
    float: right !important;
    width: 30px !important;
    height: 30px !important;
    cursor: pointer;
    z-index: 9 !important;
    position: relative !important;
    margin-right: 15px;
  }

  .mobile-nav-icon span {
    width: 100%;
    height: 2px;
    background: #fff;
    display: block;
    margin: 8px 0px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }

  .mobile-nav-icon span:nth-child(2) {
    transition: all 0.4s ease-in-out;
    position: relative;
  }

  .mobile-nav-icon span:nth-child(2)::before {
    content: "";
    width: 30px;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.4s ease-in-out;
  }

  .mobile-nav-icon span:nth-child(2)::after {
    content: "";
    width: 30px;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.4s ease-in-out;
  }

  .mobile-nav-icon.active span:nth-child(2)::before {
    content: "";
    width: 30px;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    transform: rotate(-45deg);
    transition: all 0.4s ease-in-out;
  }

  .mobile-nav-icon.active span:nth-child(2)::after {
    content: "";
    width: 30px;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    transform: rotate(45deg);
    transition: all 0.4s ease-in-out;
  }

  .mobile-nav-icon.active span:nth-child(1) {
    opacity: 0;
    visibility: hidden;
  }

  .mobile-nav-icon.active span:nth-child(3) {
    opacity: 0;
    visibility: hidden;
  }

  .mobile-nav-icon span:nth-child(2) {
    width: 100%;
    height: 2px;
    background: #fff;
    display: block;
    margin: 5px 0px;
    transition: all 0.4s ease-in-out;
    position: relative;
  }

  .mobile-nav-icon.active span:nth-child(2) {
    position: relative;
    width: 0;
  }

  .off-canvas {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    cursor: crosshair;
    background: #232323;
    top: 0;
  }

  .off-canvas.active {
    opacity: 0.5;
    visibility: visible;
  }

  .header-right-menu-bar {
    display: flex;
    align-items: center;
  }

  .bid-mobile-menu-sidebar {
    z-index: 999;
    position: fixed;
    opacity: 1;
    background: var(--primary-color);
    width: 300px;
    height: 100vh;
    overflow-y: auto;
    text-align: center;
    padding: 10px 0px;
    transition: all .3s ease;
    margin-left: -300px;

    &.active {
      margin-left: 0px;
    }
  }

  .bid-mobile-menu-sidebar {
    display: block;
  }

  .bid-mobile-menu-sidebar-full {
    ul {
      li {
        width: 100%;
        border-bottom: 1px solid #758cff;

        a {
          padding: 15px 0px;
          display: inline-block;
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          width: 100%;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  .menu-register-btn-header-mobile-hide {
    display: none;
  }
}

@media (max-width:767px) {
  .header-nav.main-header-area.container.countdown-active {
    position: relative;
    // padding-bottom: 55px;

    .countdown-wrapper.header-countdown {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
    }
  }


}