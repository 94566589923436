@import "../../../scss/variables";

.profile-container {

    
  .notification {
    background-color: #ff414163;

    .notofication_text {
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      padding: 15px 0;
      margin: 0;
      a{
          text-decoration: underline;
        &:hover{
          color: var(--primary-color);
        }
      }
    }
  }
  .profile-header {
    padding: 8px 0px;
    overflow: hidden;
    border-bottom: 2px solid #828282;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ph-left {
      float: left;
      font-weight: bold;
      font-size: 15px;
      color: #4f4f4f;
    }

    .ph-right {
      float: right;
      text-align: right;
      font-weight: bold;
      font-size: 12px;
      color: #00a9e2;
    }
  }

  .content {
    padding: 0;
  }

  .my-profile-content {
    .content_group_double {
      display: flex;
      //align-items: flex-end;
      justify-content: space-between;
     

      .input-block,
      .multi_select_block {
        width: 50%;
      }
       .input-block{
            width:49%;
      }

    }

    .content_group_single {
      width: 100%;

      .w_90 {
        width: 90%;
      }
    }

    .search-wrapper._2iA8p44d0WZ-WqRBGcAuEV {
      width: 100%;
    }

    div#currency {
      margin-bottom: 5px;
    }

    .content_group_single_icon {
      label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .name {
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #4F4F4F;
        }

        .link a {
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #00A9E2;
          text-decoration: none;
          transition: all ease-in-out 0.35s;
        }
      }

      .input-container {
        display: -ms-flexbox;
        /* IE10 */
        display: flex;
        width: 100%;
        margin-bottom: 0;

        .input-img-inside {
          padding: 14px;
          background: #F2F2F2;
          color: white;
          text-align: center;
          border: 1px solid #E0E0E0;
          border-radius: 3px;
        }

        .img-input-field {
          width: 100%;
          padding: 10px;
          outline: none;
          border: 1px solid #E0E0E0;
          border-left: 0;
          border-radius: 0 3px 3px 0;
          height: 45px;
        }

        .img-input-field::placeholder {
          color: #4F4F4F;
        }
      }
    }

    .facebook-link,
    .linkdin-link,
    .twitter-link {
      padding: 5px 0;

      .input-block-group .input-container .img-input-field {
        height: 46px;
      }
    }

    .twitter-link {
      padding-bottom: 0;
    }

  }

  .das-bid-table thead {
    background: #cccccc36;
  }

  .das-bid-table thead th {
    padding: 10px 20px;
  }

  .latest-bid-tabel h2.title {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .das-bid-table tbody td {
    padding: 8px 10px;
  }

  .das-bid-table tbody td a {
    color: #666;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }

  .das-bid-table tbody td a:hover {
    text-decoration: underline;
    color: #f33;
  }

  .button-white {
    background-color: var(--primary-color);
    padding: 7px 20px;
    border: none;
    border-radius: 3px;
    color: #013A6D;
    font-size: 11px;
    font-weight: bold;
    height: 34px;
  }

  .button-blue {
    margin-left: 10px;
    background-color: var(--primary-color);
    padding: 7px 20px;
    border: none;
    border-radius: 3px;
    color: #F2F2F2;
    font-size: 11px;
    font-weight: bold;
    height: 34px;
  }

  .scroll {
    display: block;
    overflow-x: auto;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .ml-0 {
    margin-top: 10px;
    margin-left: 0px;
  }

}

.preference-section {
  padding: 16px 30px;
}

.add_batch_preference {
  // float: right;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: #00a9e2;
  cursor: pointer;
}

.add_button_cc_email button {
  // max-width: 70px;
  font-weight: 700;
  // border-radius: 50%;
  margin-top: 15px;
  margin: auto;
}

.chckebox_scroll_container {
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}
.profile-page {
    background: #fff;
    padding: 20px;
    border-radius: 0px;
    box-shadow: 0px 3px 10px 0px #ddd;
    padding-bottom: 26px;
}
.profile-container {
    .user_preferences_table {
        margin-bottom:20px;
        .das-bid-table {
            width: 1018px;
            &.table-bordered {
                border: 1px solid gray;
            }
            thead {
                background: #879cab;
                font-size: 14px;
                color: #fff;
            }
            tbody {
                tr {
                    background-color: #fff;
                    font-size:14px;
                    .action-btn {
                        .form-submit {
                            padding: 5px 14px;
                            background: var(--primary-color);
                            color: #fff;
                            font-weight: 600;
                            &.button-blue {
                                background: #c65757;
                            }
                        }
                    }
                }
                tr:nth-of-type(2n+2) {
                    background-color: #ecf0f2;
                }
            }
        }
    }
}
.latest-bid-tabel.preferences-pagination .pagination-container.pagination-bar {
	margin-top: 20px;
	padding: 0px;
	box-shadow: none;
}
.add_button_cc_email button {
	font-weight: 700;
	margin-top: 15px;
	margin: 0;
	padding: 6px 25px;
	font-size: 13px;
	background: #00732a !important;
	border-radius: 1px;
}
.add-new-profile-email{
  align-items: center;
  justify-content: space-between;
  .input-block {
    width: calc( 100% - 50px );
  }
}
.profile-page.profile-page-style {
    .profile-container .profile-header {
        border-bottom: 1px solid #82828230;
    }
    .tabs {
        li{
            border-radius:4px;
            padding: 0 15px 5px;
            &.current {
                background-color: var(--primary-color) !important;
                border-radius: 2px;
            }
        }
    }
}